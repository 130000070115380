<template>
    <div id="services-list">
        <util-bar>
            <div></div>
        </util-bar>

        <div class="pr-3 pl-3 pt-3">

            <panel title="Filtros" icon="filter">
                <b-row>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label>Data do Serviço - Período</label>
                        <b-row>
                            <b-col>
                                <typeable-date v-model="filters.service_date.start"/>
                            </b-col>
                            <span class="align-self-center">-</span>
                            <b-col>
                                <typeable-date v-model="filters.service_date.end"/>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label>Situação: <b-icon icon="patch-question-fll" class="text-mary-beleza" id="status"></b-icon></label>
                        
                        <b-popover target="status" triggers="hover" placement="top">
                            <template #title>AJUDA</template>
                            <div class="text-center">
                                O item <strong class="text-uppercase">Serviços Válidos</strong> se refere a todos os serviços que 
                                possuem situação <strong class="text-uppercase">A Confirmar / A Realizar</strong>, 
                                <strong class="text-uppercase">Confirmado</strong> ou <strong class="text-uppercase">Não-Confirmado pelo Cliente</strong>
                            </div>
                        </b-popover>
                        
                        <b-form-select v-model="filters.status" :options="status_options"></b-form-select>
                        <b-form-invalid-feedback id="input-live-feedback">
                            Mostra erros
                        </b-form-invalid-feedback>
                    </b-col>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label for="">Situação Recebimento</label>
                        <b-form-select :options="transaction_status_options" v-model="filters.received_status"></b-form-select>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label for="">Recebimento</label>
                        <button-group v-model="filters.received"></button-group>
                    </b-col>

                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label>Data de Recebimento - Período</label>
                        <b-row>
                            <b-col>
                                <typeable-date v-model="filters.received_date.start"/>
                            </b-col>
                            <span class="align-self-center">-</span>
                            <b-col>
                                <typeable-date v-model="filters.received_date.end"/>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label for="">Forma de Recebimento</label>
                        <b-form-select :options="receipt_type_options" v-model="filters.received_type"></b-form-select>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label for="">Situação Pagamento</label>
                        <b-form-select :options="transaction_status_options" v-model="filters.payment_status"></b-form-select>
                    </b-col>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label>Pagamento</label>
                        <button-group v-model="filters.payment"></button-group>
                    </b-col>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label>Data de Pagamento - Período</label>
                        <b-row>
                            <b-col>
                                <typeable-date v-model="filters.payment_date.start"/>
                            </b-col>
                            <span class="align-self-center">-</span>
                            <b-col>
                                <typeable-date v-model="filters.payment_date.end"/>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label>ASAAS - Pagamento</label>
                        <button-group v-model="filters.payment_assas"></button-group>
                    </b-col>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label>Zona (Endereço Profissional)</label>
                        <b-form-select v-model="filters.worker_zone" :options="zone_options"></b-form-select>
                    </b-col>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label>ASAAS - Cobrança Emitida</label>
                        <button-group v-model="filters.collection_assas"></button-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" md="5" lg="5">
                        <label>Serviços:</label>
                        <multiselect 
                            v-model="filters.services"
                            :options="services"
                            :multiple="true"
                            placeholder="Selecione..."
                            selectedLabel="Selecionado"
                            selectLabel="Pressione enter para selecionar"
                            deselectLabel="Pressione enter para remover"
                            track-by="id"
                            label="name"
                        >
                            <template slot="noResult">
                                Nenhum resultado encontrado
                            </template>
                            <template slot="noOptions">
                                Nenhum resultado encontrado
                            </template>
                        </multiselect>
                    </b-col>
                    <b-col sm="12" md="4" lg="4">
                        <label>Cupom:</label>
                        <multiselect
                            :internalSearch="false"
                            v-model="filters.promotions"
                            :options="promotions"
                            :multiple="true"
                            placeholder="Selecione..."
                            selectedLabel="Selecionado"
                            selectLabel="Pressione enter para selecionar"
                            deselectLabel="Pressione enter para remover"
                            :loading="loading_promotions"
                            @search-change="SearchPromotions"
                        >
                            <template slot="noResult">
                                Nenhum resultado encontrado
                            </template>
                            <template slot="noOptions">
                                Pesquisar...
                            </template>
                        </multiselect>
                    </b-col>
                    <b-col sm="12" md="3" lg="3" class="form-group">
                        <label></label>
                        <p>
                            <b-button class="mt-2" block @click="CleanFilters" variant="outline-danger">Limpar Filtros</b-button>
                        </p>
                    </b-col>
                </b-row>
                <b-row class="d-flex">
                    <b-col md="3" lg="3">
                        <label>Tempo restante para iniciar:</label>
                        <b-form-select v-model="filters.time_remaining" :options="time_remaining_options"></b-form-select>
                    </b-col>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label for="">Cliente Parceiro</label>
                        <button-group v-model="filters.partner"></button-group>
                    </b-col>
                    <b-col sm="12" md="4" lg="4" class="form-group">
                        <label for="">Problemas</label>
                        <button-group v-model="filters.problem"></button-group>
                    </b-col>
                    <b-col v-if="alert_due_date" md="7" lg="7" class="text-left mt-3">
                        <b-icon icon="exclamation-circle" class="rounded-circle bg-light" id="alert_due_date" font-scale="3" variant="danger"></b-icon>
                        <b-tooltip target="alert_due_date" variant="mary-beleza-light" placement="bottom">
                            <h5>Atenção</h5> 
                            <p>Possui <strong>{{alert_due_date}}</strong> agendamento(s) com menos de 3 horas!</p>
                        </b-tooltip>
                    </b-col>
                    <b-col md="2" lg="2">
                        <div>
                            <label>Legendas</label>
                            <subtitles :items="subtitles"></subtitles>
                        </div>
                    </b-col>
                </b-row>
            </panel>
            <panel title="Painel">
                <b-row class="form-group">
                    <b-col md="12">
                        <b-row class="form-group">
                            <b-col sm="12" md="12" lg="12">
                                
                                <rich-table
                                    ref="servicesRichTable"
                                    v-on:input="ChangeTableConfig"
                                    :numbers="table_numbers"
                                    :loading="loadingData"
                                    :cols="table_cols"
                                    vueComponent="b-table-schedule-list"
                                    default_sort_order="desc"
                                    :default_perPage="100"
                                    class="b-table-service-list">
                                    <template v-if="items && items.length > 0">
                                        <tr v-for="(item, key) in items" :key="key" :class="(CheckScheduleDate(item) || CheckScheduleAcceptDate(item) ? 'bg-alert-date-final' : '')">
                                            <td class="d-flex flex-column">
                                                <span class="block">
                                                    <img :src="img_calendar" width="16" v-b-tooltip title="Data inicial" />
                                                    <span v-if="item.service_date_end">
                                                        {{item.service_date | moment('DD/MM/YYYY HH:mm')}}h
                                                    </span>
                                                    <span v-else>
                                                        {{item.service_date | moment('DD/MM/YYYY')}}
                                                    </span>
                                                </span>

                                                <span class="block">
                                                    <span v-if="item.service_date_end">
                                                        <img :src="img_time" width="16" v-b-tooltip title="Data final" />
                                                        {{item.service_date | moment('HH:mm')}}h às {{item.service_date_end | moment('HH:mm')}}h
                                                    </span>
                                                    <span v-else>
                                                        <img :src="img_time" width="16" :id="'service-time' + key" title="Data final" />
                                                        <b-tooltip :target="'service-time' + key" variant="mary-beleza-light" placement="bottom">
                                                            <strong>Opções de Horários:</strong> 
                                                            <p v-for="(hour, index) in item.hours" :key="index" class="m-1"><strong>-</strong> {{ListDateTimeConcat(item.service_date, hour.hour, item.service.duration)}}</p>
                                                        </b-tooltip>
                                                    </span>
                                                </span>
                                            </td>
                                            <td>
                                                {{item.number}}
                                                <div v-if="item.payment_type == constants.SCHEDULE_PAYMENT_TYPE_CREDITCARD">
                                                    <b-badge variant="info" pill>Cartão</b-badge>
                                                </div>
                                                <div v-else-if="item.payment_type == constants.SCHEDULE_PAYMENT_TYPE_PIX">
                                                    <b-badge variant="warning" pill>Pix</b-badge>
                                                </div>
                                                <div class="mt-2" v-if="ChargebackDispute(item)">
                                                    <b-badge variant="warning" pill>DEVOLUÇÃO EM DISPUTA</b-badge>
                                                </div>
                                               
                                            </td>
                                            <td>
                                                <strong style='display: block;' v-if="item.service.parent">{{item.service.parent.name}}: </strong> {{ item.service.name }}
                                            </td>
                                            <td align="center">
                                                <span class="schedule_confirmation" @click="ScheduleConfirm(item)" v-if="!item.status_reverting">
                                                    <b-badge pill v-schedule-status="item.status"></b-badge>
                                                </span>
                                                <help-icon v-else class="no-print" icon="spinner" animation="spin" color="#ef62a3"><strong>Revertendo</strong></help-icon>
                                                <p class="mt-1">
                                                    <img v-if="item.schedule_checkins.length > 0" @click="ViewScheduleCheckins(key)" :src="img_camera" width="25" class="mr-2 zoom-1-2" v-b-tooltip="'Agendamento possui '+item.schedule_checkins.length+' check-in(s).'" />
                                                    <b-icon v-if="item.schedule_problems && item.schedule_problems.filter(p => p.status != 0).length > 0" @click="ViewScheduleProblems(key)" icon="exclamation-circle-fill" variant="danger" style="width:20px; height: 20px;" class="mr-2" v-b-tooltip="'O agendamento possui '+item.schedule_problems.filter(p => p.status != 0).length+' problema(s).'"></b-icon>
                                                </p>
                                            </td>
                                            <td>
                                                <div class="row" v-if="item.worker">
                                                    <span class="col-md-12 link-open" @click="$router.push({ path: '/profissionais/historico/' + item.worker_id })">{{item.worker.person.name}}</span>
                                                    <div class="col-md-12">
                                                        <span v-if="item.worker.person.phones.length > 0">
                                                            <img :src="img_service_phone" width="18" :id="'worker-phone' + key" class="mr-2" />
                                                            <b-tooltip :target="'worker-phone' + key" variant="mary-beleza-light" placement="bottom">
                                                                <strong>TELEFONES:</strong> 
                                                                <p v-for="(item, index) in item.worker.person.phones" :key="index" class="m-1">{{item.phone_number | formatPhones}}</p>
                                                            </b-tooltip>
                                                        </span>
                                                        <span v-if="!item.worker.person.email">
                                                            <img :src="img_not_email" width="20" class="mr-2" v-b-tooltip title="Profissional não possui um email cadastrado no sistema." />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <img :src="img_notconfirmedworker" width="25" class="mr-2" v-b-tooltip title="Sem profissional." />
                                                    <div v-if="item.preference_worker_id">
                                                        <b-badge variant="warning">Preferencial</b-badge>
                                                    </div>
                                                    
                                                </div>
                                                <span>
                                                    <b-icon icon="exclamation-circle-fill" variant="danger" style="width:20px; height: 20px;" v-if="CheckScheduleDate(item)" class="mr-2" v-b-tooltip="'Serviço tem menos de 3h para data final!'"></b-icon>
                                                </span>
                                            </td>
                                            <td>
                                                <div class="row">
                                                    <span class="col-md-12 link-open" @click="$router.push({ path: '/clientes/cadastro/' + item.customer_id })">{{item.customer.person.name}}</span>
                                                    <div class="col-md-12">
                                                        <span v-if="item.customer.person.phones.length > 0">
                                                            <img :src="img_service_phone" width="20" :id="'customer-phone' + key" class="mr-2" />
                                                            <b-tooltip :target="'customer-phone' + key" variant="mary-beleza-light" placement="bottom">
                                                                <strong>TELEFONES:</strong> 
                                                                <p v-for="(item, index) in item.customer.person.phones" :key="index" class="m-1">{{item.phone_number | formatPhones}}</p>
                                                            </b-tooltip>
                                                        </span>
                                                        <span v-if="!item.customer.person.email">
                                                            <img :src="img_not_email" width="20" class="mr-2" v-b-tooltip title="Cliente não possui um email cadastrado no sistema." />
                                                        </span>
                                                        <span v-if="item.promotion">
                                                            <img :src="img_coupon" width="20" class="mr-2" :id="'customer-promotion-'+key" />
                                                            <b-tooltip :target="'customer-promotion-'+key" variant="mary-beleza-light" placement="bottom">
                                                                <strong>{{ item.promotion.coupon }}</strong>
                                                                <p>
                                                                    <strong>Desconto: </strong>
                                                                    <span v-if="item.promotion.value_type == 1">
                                                                        {{item.promotion.value}}%
                                                                    </span>
                                                                    <span v-else>
                                                                        {{item.promotion.value | currency}}
                                                                    </span>
                                                                    <span v-if="item.promotion.description">
                                                                        <strong>Descrição: </strong>
                                                                        <span>
                                                                            {{item.promotion.description}}
                                                                        </span>
                                                                    </span> 
                                                                </p> 
                                                            </b-tooltip>
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="no-print">
                                                <template v-if="item.hiring_type != 'T'">
                                                    <span v-if="item.promotion">
                                                        {{item.customer_value | currency}} <br>
                                                        <strike class="text-danger">{{item.customer_total | currency}}</strike>
                                                    </span>  
                                                    <span v-else>
                                                        {{item.customer_value | currency}}
                                                    </span>
                                                    <span v-if="item.customer_value_motive">
                                                        <b>*</b>
                                                    </span>
                                                    <div>
                                                        <template v-if="!item.schedule_online">
                                                            <template v-if="GetTransactionRecipe(item) && !IsTransactionPending(GetTransactionRecipe(item))">
                                                                <img :src="img_receipt_ok" v-b-tooltip :title="'Data do recebimento: ' + moment(GetTransactionRecipe(item).payment_date).format('DD/MM/YYYY')" width="18" class="mr-2">
                                                            </template>
                                                            <template v-else>
                                                                <img :src="img_receipt_not_ok" @click="GoToReceipt(item)" width="18" class="mr-2" v-b-tooltip title="Não recebido."/>
                                                            </template>
                                                        </template>
                                                        </div>
                                                </template>
                                            </td>                                        
                                            <td>
                                                {{item.worker_value | currency}}
                                                <div>
                                                    <template v-if="GetTransactionExpense(item) && !IsTransactionPending(GetTransactionExpense(item))">
                                                        <img :src="img_payment_ok" width="18" class="mr-2" v-b-tooltip :title="'Data do pagamento: ' + moment(GetTransactionExpense(item).payment_date).format('DD/MM/YYYY')">
                                                    </template>
                                                    <template v-else>
                                                        <img :src="img_payment_not_ok" class="mr-2" width="18" v-b-tooltip title="Não pago."/>
                                                    </template>
                                                </div>
                                            </td>
                                            <td>
                                                {{item.customer_value - item.worker_value | currency}}

                                            </td>
                                           <td>
                                                <img v-if="item.obs" v-b-tooltip title="Observação" @click="sweealert('', item.obs)" :src="img_service_note" width="25" class="c-pointer">
                                                <strong v-else v-b-tooltip title="Sem observação" class="text-danger ">-</strong>
                                            </td>
                                            <td>
                                                <b-button v-if="has_permission('services-view')" variant="primary" size="sm" @click="$router.push({ path: '/servicos/servico/' + item.id })" v-b-tooltip title="Editar serviço">
                                                    <b-icon icon="pen" font-scale="1" />
                                                </b-button>                                    
                                            </td>
                                            <!-- <td>
                                                <button v-if="has_permission('services-delete')" class="btn btn-danger btn-primary btn-sm" @click="deleteSchedule(item)">
                                                    <b-icon icon="trash" font-scale="1" />
                                                </button>
                                            </td> -->
                                        </tr>
                                        <tr v-if="items && items.length > 0">
                                            <td  colspan="6"  style="text-align:center; font-size:16px !important; font-weight:700" > 
                                                TOTAIS
                                            </td>
                                            <td style="font-weight:700; font-size:14px !important" >
                                                {{ TotalReceipt(items)|currency }}
                                            </td>
                                            <td style="font-weight:700; font-size:14px !important">
                                                {{ TotalPayment(items)|currency }}
                                            </td>
                                            <td colspan="3" style="font-weight:700; font-size:14px !important;">
                                                {{ TotalProfit(items)|currency }}
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td :colspan="table_cols.length" class="text-center py-3" style="font-size:16px !important;">Nenhum registro encontrado</td>
                                        </tr>
                                    </template>
                                </rich-table>
                                <b-modal class="text-center" title="Check-ins" hide-footer ref="modal-view-checkins" size="lg" scrollable centered>
                                    <div v-if="scheduleCheckinKey != null">
                                        <div class="text-center mt-3">
                                            <!--<h1 class="text-muted">Check-ins</h1>-->
                                            <div>
                                                <strong class="mr-2">Agendamento:</strong>
                                                <strong class="text-muted">{{ items[scheduleCheckinKey].number }}</strong>
                                            </div>
                                        </div>
                                        <div class="container mt-5">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="main-timeline8">
                                                        <div class="timeline" v-for="(item, key) in items[scheduleCheckinKey].schedule_checkins" :key="key">
                                                            <div>
                                                                <span class="timeline-icon"></span>
                                                                <span class="year"><strong>{{ CheckinStatus(item.type) }} {{ item.system ? '- Sistema' : '' }}</strong></span>
                                                                <div class="timeline-content" :style="([constants.TYPE_I_GOT_TO_THE_SERVICE, constants.TYPE_STERILIZATION_OF_MATERIALS, constants.TYPE_COMPLETED_SERVICE].includes(item.type) ? 'background-size: cover; height:350px; background-image: url('+(item.photo ? item.photo : not_image)+');' : '')">
                                                                    <strong class="rounded p-1" style="margin-top:-5%; background: rgba(255, 255, 255, 0.5)">{{ item.created_at | moment("DD/MM/YYYY HH:mm") }}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-modal>
                                <b-modal hide-header hide-footer ref="modal-view-problems" size="md" scrollable centered>
                                    <div v-if="scheduleProblemsKey != null">
                                        <div class="text-center mt-3 mb-4">
                                            <h1 class="text-muted">Problemas</h1>
                                            <div>
                                                <strong class="mr-2">Agendamento:</strong>
                                                <strong class="text-muted">{{ items[scheduleProblemsKey].number }}</strong>
                                            </div>
                                        </div>
                                        <b-row>
                                            <b-col v-for="(item, index) in items[scheduleProblemsKey].schedule_problems" :key="index" sm="12" md="12" lg="12">
                                                <b-card class="mb-4 shadow-lg ml-2">
                                                    <b-row>
                                                        <b-col sm="12" md="12" lg="12" class="justify-content-between d-flex">
                                                            <div>
                                                                <label>Tipo: </label>
                                                                <span class="ml-2 text-muted">{{ ProblemsType(item.type) }}</span>
                                                            </div>
                                                        </b-col>
                                                        <b-col sm="8" md="8" lg="8">
                                                            <label>Informado em: </label>
                                                            <span class="ml-2 text-muted">{{ item.created_at | moment("DD/MM/YYYY HH:mm") }}</span>
                                                        </b-col>
                                                        <b-col sm="4" md="4" lg="4">
                                                            <label>Resolvido: </label>
                                                            <span class="ml-2 text-muted" v-if="!item.status">SIM</span>
                                                            <span class="ml-2 text-danger" v-else>NÃO</span>
                                                        </b-col>
                                                        <b-col sm="10" md="10" lg="10">
                                                            <label class="mr-2">Observação: </label>
                                                            <p>{{ item.obs }}</p>
                                                        </b-col>
                                                    </b-row>
                                                </b-card>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-modal>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <div style="text-align:right;display: flex;justify-content: end;">
                        <table  class="tb-valid-services" v-if="items && items.length > 0">
                            <tbody>
                                <tr> 
                                    <td colspan="2" class="column-valid-services">
                                        <strong>TOTAIS NO PERÍODO</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding-left: 10px">
                                        <strong>Recebimentos:</strong>
                                    </td>
                                    <td style="padding-right: 10px">
                                        {{ valid.customer_value|currency }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding-left: 10px">
                                        <strong>Pagamentos:</strong>
                                    </td>
                                    <td style="padding-right: 10px">
                                        {{ valid.worker_value|currency }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding-left: 10px">
                                        <strong>Lucro:</strong>
                                    </td>
                                    <td style="padding-right: 10px">
                                    {{ valid.customer_value - valid.worker_value|currency }}
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        &nbsp;
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding-left: 10px; padding-right: 5px; padding-bottom: 5px">
                                        <strong>Total de Serviços Válidos: </strong>
                                    </td>
                                    <td>
                                        {{valid.services}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>  
            </panel>
        </div>
    </div>
</template>

<script>
import Panel from "@/components/Panel"
import RichTable from '@/components/RichTable';
import ButtonGroup from '@/components/ButtonGroup';
import TypeableDate from '@/components/TypeableDate'
import _clone from 'lodash/clone'
import _merge from 'lodash/merge'
import HelpIcon from '@/components/HelpIcon'
import ImageLoader from '@/mixins/ImageLoader'
import UtilBar from '@/components/UtilBar'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import Constants from '@/constants'
import { mapGetters } from 'vuex'
import Subtitles from '@/components/Subtitles'

export default {
    mixins: [ ImageLoader ],

    components: {
        Panel, 
        RichTable, 
        ButtonGroup,
        HelpIcon, 
        UtilBar,
        TypeableDate,
        Multiselect,
        Subtitles
    },

    data() {
        return {
            promotions: [],
            loading_promotions: false,
            options_problems_type: [
                { value: Constants.TYPE_WORKER_DELAY, text: 'Atraso da Profissional' },
                { value: Constants.TYPE_CUSTOMER_NOT_FOUND, text: 'Cliente não está' },
                { value: Constants.TYPE_ADDRESS_NOT_FOUND, text: 'Endereço não encontrado' },
                { value: Constants.TYPE_OTHERS, text: 'Outros' },
                { value: Constants.TYPE_CUSTOMER_DIFFERENT_SERVICE, text: 'Cliente quer serviço diferente do contratado' },
                { value: Constants.TYPE_CUSTOMER_NOT_SERVICE, text: 'Cliente não quer o serviço' },
                { value: Constants.TYPE_LOCATION_NOT_CONDITIONS, text: 'Local não apresenta condições de realização do trabalho' },
            ],
            show: true,
            multiselect: false,
            scheduleProblemsKey: null,
            table_numbers: {
                size: null,
                total: null,
                found: null,
                footer: null
            },

            loadingData: false,

            status_options: [
                { value: null, text: "Todos" },
                { value: Constants.STATUS_OPPORTUNITY, text: "Oportunidades" },
                { value: Constants.STATUS_ACCEPT, text: "Aceitos" },
                { value: Constants.STATUS_IN_PREPARATION, text: "Em preparação" },
                { value: Constants.STATUS_EN_ROUTE, text: "Profissional a Caminho" },
                { value: Constants.STATUS_ARRIVED, text: "Profissional Está no Local" },
                { value: Constants.STATUS_FINISHED, text: "Finalizados" },
                { value: Constants.STATUS_CANCELED_WORKER, text: "Cancelados Profissional" },
                { value: Constants.STATUS_CANCELED_CUSTOMER, text: "Cancelados Cliente" },
                { value: Constants.STATUS_CANCELED_MARY_BELEZA, text: "Cancelados MB" },
                { value: Constants.STATUS_STERILIZATION_OF_MATERIALS, text: "Esterilização" },
                { value: Constants.STATUS_PAYMENT_IN_PROCESS, text: "Pagamento em processamento" },
                { value: Constants.STATUS_LOST, text: "Oportunidade Perdida" },
                { value: Constants.STATUS_VALID_SERVICES, text: "Serviços Válidos" }
            ],

            zone_options: [
                { value: null, text: "Todas" },
                { value: 0, text: "Centro" },
                { value: 1, text: "Leste" },
                { value: 2, text: "Norte" },
                { value: 3, text: "Oeste" },
                { value: 4, text: "Sul" },
            ],

            receipt_type_options: [
                { value: null, text: 'Todos' },
                { value: Constants.PAYMENT_TYPE_MONEY, text: 'Dinheiro' },
                { value: Constants.PAYMENT_TYPE_BOLETO, text: 'Boleto Bancário' },
                { value: Constants.PAYMENT_TYPE_CREDITCARD, text: 'Cartão de Crédito' },
                { value: Constants.PAYMENT_TYPE_DEBITCARD, text: 'Cartão de Débito' },
                { value: Constants.PAYMENT_TYPE_PREDATED_CHECK, text: 'Cheque Pré-Datado' },
                { value: Constants.PAYMENT_TYPE_DEPOSIT_CHECKING_ACCOUNT, text: 'Depósito em Conta Corrente' },
                { value: Constants.PAYMENT_TYPE_PAYCHECK, text: 'Cheque á Vista' },
            ],

            transaction_status_options: [
                { value: null, text: 'Todos' },
                { value: Constants.STATUS_DRAFT, text: 'Previsto' },
                { value: Constants.STATUS_UNKNOW, text: 'Desconhecido' },
                { value: Constants.STATUS_PENDING, text: 'Pendente' },
                { value: Constants.STATUS_RECEIVED, text: 'Recebido' },
                { value: Constants.STATUS_RECEIVED_IN_CASH, text: 'Recebido em dinheiro' },
                { value: Constants.STATUS_OVERDUE, text: 'Vencido' },
                { value: Constants.STATUS_CONFIRMED, text: 'Confirmação' },
                { value: Constants.STATUS_PROCESSING, text: 'Em processamento' },
                { value: Constants.STATUS_FAIL, text: 'Falha' },
            ],

            checkins_status_options: [
                { value: Constants.TYPE_ON_THE_WAY, text: 'PROFISSIONAL A CAMINHO' },
                { value: Constants.TYPE_I_GOT_TO_THE_SERVICE, text: 'PROFISSIONAL ESTÁ NO LOCAL' },
                { value: Constants.TYPE_STERILIZATION_OF_MATERIALS, text: 'ESTERILIZAÇÃO DOS MATERIAIS' },
                { value: Constants.TYPE_COMPLETED_SERVICE, text: 'SERVIÇO CONCLUÍDO' },
            ],
            
            valid:{
                customer_value: null,
                worker_value: null,
                services: null
            },
            
            items: [],
            alert_due_date: 0,
            scheduleCheckinKey: null,

            time_remaining_options: [
                { value: null, text: 'Selecionar' },
                { value: 1, text: '1 hora' },
                { value: 2, text: '2 hora' },
                { value: 3, text: '3 hora' },
                { value: 4, text: '4 hora' },
                { value: 5, text: '5 hora' },
                { value: 6, text: '6 hora' },
                { value: 7, text: '7 hora' },
            ]
        }
    },

    mounted(){
        this.$store.dispatch('service/getServices');
        this.$pusher.listen("schedule-update-list", this.OnUpdateScheduleList);
    },
    beforeDestroy() {
        this.$pusher.stop("schedule-update-list", this.OnUpdateScheduleList);
    },
    computed: {
        ...mapGetters('auth', [ 
            'has_permission' 
        ]),
        
        table_cols() {
            var cols = [
                { id: 'service_date', text: 'Data', class: 'cols_service_date', sort_order: 'desc' },
                { id: 'number', text: 'N°', class: 'cols_number' },
                { id: 'service_id', text: 'Serviço', class: 'cols_service' },
                { id: 'status', text: 'Status', class: 'cols_status' },
                { id: 'worker.name', text: 'Profissional', class: 'cols_worker_name' },
                { id: 'customer.name', text: 'Cliente', class: 'cols_customer' },
                { id: null, text: 'Recebimento', class: 'cols_recebimento' },
                { id: null, text: 'Pagamento', class: 'cols_payment' },
                { id: null, text: 'Lucro', class: 'cols_profit' },
                { id: null, text: 'Obs', class: 'cols_obs' },
                { id: null, text: '', class: 'cols_button_1' },
                /* { id: null, text: '', class: 'cols_button_2' }, */
            ];
            return cols;

        },

        filters: {
            get() {
                return this.$store.state.service.filters.schedules;
            }
        },

        services: {
            get() {
                return this.$store.state.service.services;
            }
        },

        constants(){
            return Constants;
        },

        subtitles() {
            let lines = [
                { icon: 'fas fa-square', description: "Serviço com menos de 3 horas", color: 'transparent', backgroundColor: '#ffbeb7' },
                { image: 'img_calendar', description: "Data do Serviço" },
                { image: 'img_time', description: "Horário(s)" },
                { image: 'img_service_note', description: "Observações" },
                { image: 'img_service_phone', description: "Telefone(s) da profissional/cliente" },
                { image: "img_notconfirmedworker", description: "Serviço não possui profissional" },
                { image: "img_not_email", description: "Cliente/Profissional não possui um email cadastrado no sistema." },
                { image: "img_payment_ok", description: "Pago à profissional" },
                { image: "img_payment_not_ok", description: "NÃO Pago à profissional" },
                { image: "img_receipt_ok", description: "Recebido do cliente" },
                { image: "img_receipt_not_ok", description: "NÃO recebido do cliente" },
                { image: "img_coupon", description: "Cupom adicionado" },
                { image: "img_camera", description: "Check-in realizados pela profissional" },
                { icon: 'pen', description: "Editar", variant: 'primary', type: 'btn' },
                { description: "Serviço que não possui profissional", class: 'bg-mary-beleza text-white', type: 'badge', title: 'Oportunidade' },
                { description: "Serviço que possui profissional", class: 'bg-warning text-white', type: 'badge', title: 'Aceito' },
                { description: "Profissional esta indo para o local do serviço", class: 'bg-info text-white', type: 'badge', title: 'Profissional a Caminho' },
                { description: "Profissional chegou no local do serviço", class: 'bg-primary text-white', type: 'badge', title: 'Profissional Está no Local' },
                { description: "Serviço foi realizado", class: 'bg-success text-white', type: 'badge', title: 'Finalizado' },
                { description: "Serviço cancelado pela profissional", class: 'bg-danger text-white', type: 'badge', title: 'Cancelado - Profissional' },
                { description: "Serviço cancelado pelo cliente", class: 'bg-danger text-white', type: 'badge', title: 'Cancelado - Cliente' },
                { description: "Serviço cancelado pela Mary Beleza", class: 'bg-danger text-white', type: 'badge', title: 'Cancelado - Mary Beleza' },
                { description: "Profissional esterelizou os materiais", class: 'bg-warning text-white', type: 'badge', title: 'Esterilização dos materiais' },
                { description: "Pagamento em processamento com o ASSAS", class: 'bg-info text-white', type: 'badge', title: 'Pagamento em processamento' },
                { description: "Serviço solicitado por cartão de crédito", class: 'bg-info text-white', type: 'badge', title: 'Cartão' },
                { description: "Serviço solicitado por Pix", class: 'bg-warning', type: 'badge', title: 'Pix' },
            ];

            return lines;
        },
    },

    watch: {
        'filters': {
            handler: function () {
               this.LoadData();
            },
            deep: true
        },
    },
    
    methods: {
        ChargebackDispute(transaction) {
            return [Constants.STATUS_CHARGEBACK_DISPUTE].includes(transaction.status);
        },        

        CheckNegativeProfit(profit){
            return profit < 0
        },

       TotalReceipt(items){
            let total = items
                            .map(receipt => receipt.customer_value)
                            .reduce((receiptAnt, receiptAtual) => receiptAnt + receiptAtual)
            return total
        },

        TotalPayment(items){
            let total = items
                            .map(payment => payment.worker_value)
                            .reduce((paymentAnt, paymentAtual) => paymentAnt + paymentAtual)
            return total
        },

        TotalProfit(items){
            let total = items
                            .map(profit => profit.customer_value - profit.worker_value)
                            .reduce((profitAnt, profitAtual) => profitAnt + profitAtual)
            return total
        },

        ProblemsType(value){
            let type = this.options_problems_type.find(type => type.value === value);
            return type?.text
        },

        SearchPromotions(coupon) {
            this.loading_promotions = true;
            clearTimeout(this.__handle);
            this.__handle = setTimeout(() => {
                this.$store.dispatch('promotion/search', coupon).then(response => {
                    this.promotions = response.map(i => i.coupon);
                    this.loading_promotions = false;
                }).catch(() => {
                    this.loading_promotions = false;
                });
            }, 500);
        },

        ViewScheduleProblems(key) {
            this.scheduleProblemsKey = key;
            this.$refs['modal-view-problems'].show();
        },
        OnUpdateScheduleList(data) {
            if (!data) {
                return;
            }
            var index = this.items.findIndex(item => item.id == data.id);
            if (index != -1) {
                Object.assign(this.items[index], data);
            }else{
                this.LoadData();
            }
        },
        ViewScheduleCheckins(key) {
            this.scheduleCheckinKey = key;
            this.$refs['modal-view-checkins'].show();
        },
        
        GetTransactionExpense(schedule) {
            return schedule.transactions.find(t => t.type == Constants.TYPE_EXPENSE);
        },

        GetTransactionRecipe(schedule) {
            return schedule.transactions.find(t => t.type == Constants.TYPE_RECIPE);
        },
        
        IsTransactionPending(transaction) {
            return [Constants.STATUS_PROCESSING, Constants.STATUS_FAIL, Constants.STATUS_DRAFT, Constants.STATUS_PENDING, Constants.STATUS_OVERDUE].includes(transaction.status);
        },

        CheckinStatus(value){
            let status = this.checkins_status_options.find(status => status.value === value);
            return status?.text
        },

        moment(...args){
            return moment(...args);
        },

        CalculateValueCustomer(customer_value, promotion_type, promotion_value){
            var value = promotion_type == 1 ? (customer_value + ((customer_value * promotion_value)/100)) : (customer_value + promotion_value);
            return value
        },

        ListDateTimeConcat(date, time, duration){
            var dateTime = moment(date).format("YYYY-MM-DD")+' '+time;
            return moment(dateTime).format("HH:mm") +'h às '+ moment(dateTime).add(duration, "minutes").format("HH:mm")+'h';
        },

        CheckScheduleDate(item, minutes = 180){
            var inicial_date = null;
            var diff_date = null;
            if(item.status == Constants.STATUS_OPPORTUNITY){
                if(item.hours.length > 0){
                    inicial_date = moment(this.MinDateTimeSchedule(item.service_date, item.hours));
                    diff_date = inicial_date.diff(moment(), 'minutes')
                }else{
                    inicial_date = moment(item.service_date);
                    diff_date = inicial_date.diff(moment(), 'minutes')
                }
                /* if(diff_date > 0 && diff_date < minutes){ */
                if(diff_date < minutes){
                    return true
                }
                return false
            }
            return false
        },

        CheckScheduleAcceptDate(item, minutes = 60){
            if(item.status == Constants.STATUS_ACCEPT){
                var inicial_date = moment(item.service_date);
                var diff_date = inicial_date.diff(moment(), 'minutes')

                /* if(diff_date > 0 && diff_date < minutes){ */
                if(diff_date < minutes){
                    return true
                }
                return false
            }
            return false
        },

        MinDateTimeSchedule(service_date ,hours){
            let min = hours[0].hour;
            for (let index = 0; index < hours.length; index++) {
                if(min > hours[index].hour){
                    min = hours[index].hour
                }  
            }
            return moment(service_date).format("YYYY-MM-DD")+" "+min;
        },

        ChangeTableConfig(value) {
            this.table_config = value
            this.LoadData();
        },

        LoadData(){
            this.loadingData = true;
            var params = _clone(_merge(this.filters, this.table_config));

            this.$store.dispatch('service/getSchedules', params).then(response => {
                this.loadingData = false;

                var data = response.data

                this.items = data.items ? data.items : [];

                this.table_numbers.size = data.size ? data.size : 0;
                this.table_numbers.found = data.found ? data.found : 0;
                this.table_numbers.total = data.total ? data.total : 0;
                this.table_numbers.footer = data.footer ? data.footer : 0;
                this.alert_due_date = data.alert_due_date ? data.alert_due_date : 0;

                this.valid.customer_value = data.valid.customer_value ? data.valid.customer_value : 0;
                this.valid.worker_value = data.valid.worker_value ? data.valid.worker_value : 0;
                this.valid.services = data.valid.services ? data.valid.services : 0;

            }); 
        },

        CleanFilters() {
            this.$store.commit('service/SET_CLEAN_FILTERS');
            this.$refs["servicesRichTable"].CleanFilters();
        },

        sweealert(icon, text) {
            this.$swal({
                showConfirmButton: true,
                text: text,
                icon: icon,
                padding: 20
            })
        },

        deleteSchedule(schedule){
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Apagar`,
                html: `Deseja realmente apagar o agendamento de nº <strong>${schedule.number}</strong> da(o) cliente <strong>${schedule.customer.person.name}</strong>?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('service/deleteSchedule', schedule.id).then(data => {
                        this.sweealert('success', data.success)
                    }).catch(error => {
                        this.$swal({
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            title: 'Atenção!',
                            text: error.response.data.error,
                            icon: 'error',
                            padding: 20,
                        })
                    });
                }
            })
        },

    }
}
</script>

<style>
.bg-alert-date-final {
    background-color: #ffbeb7 !important;
}
.tb-valid-services{
    margin-right: 0;
    text-align: left;
    width: auto;
    position: relative;
    background: #ffe1ef;
    border-radius: 8px;
    margin: 15px 0 10px 0;
}
.column-valid-services{
    text-align: center;
    background: #FFC2DF;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
</style>