export default class Filters {

    constructor(status) {
        this.status = status,
        this.habilities = []
        this.has_mei = null
        this.has_vehicle = null
        this.accept_home_care = null
        this.another_beauty_app = null
        this.created_at = null
        this.imported_from = null
        this.existence = null
        this.who_existence = null
        this.scheduling_interview = null
        this.scheduling_training_hability = null
        this.blocked = null
        this.zone = null
        this.number_of_contacts = null
    }
}