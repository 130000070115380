<template>
<div>
    <panel :loading="loading" title="INFORMAÇÕES PESSOAIS" icon="address-card" icon-lib="far">
        <b-row>
            <b-col md="12">
                <b-row class="form-group">
                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label class="required">Nome:</label>
                            <template v-if="! OpenForm.personalInformation">
                                <p>{{name}}</p>
                            </template>
                            <template v-else>
                                <b-form-input v-model="name" placeholder="Nome" trim></b-form-input>
                                <b-form-invalid-feedback :state="!errors.name">
                                    <div v-for="error in errors.name" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label>Sexo:</label>
                            <template v-if="! OpenForm.personalInformation">
                                <p v-if="!gender_name">
                                    -
                                </p>
                                <p v-else>{{gender_name}}</p>
                            </template>
                            <template v-else>
                                <b-form-radio-group v-model="gender" :options="gender_options" button-variant="outline-primary" name="radio-btn-outline"></b-form-radio-group>
                                <b-form-invalid-feedback :state="!errors.gender">
                                    <div v-for="error in errors.gender" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label>Estado Civil:</label>
                            <template v-if="! OpenForm.personalInformation">
                                <p v-if="!marital_name">
                                    -
                                </p>
                                <p v-else>{{marital_name}}</p>
                            </template>
                            <template v-else>
                                <b-form-select v-model="marital" :options="marital_options">
                                    <template #first>
                                        <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback :state="!errors.marital">
                                    <div v-for="error in errors.marital" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="form-group">
                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label>CPF:</label>
                            <template v-if="! OpenForm.personalInformation">
                                <p v-if="!national_document">
                                    -
                                </p>
                                <p v-else>{{national_document | formatDocuments }}</p>
                            </template>
                            <template v-else>
                                <the-mask :mask="'###.###.###-##'" class="form-control" v-model="national_document" placeholder="CPF" maxlength="14" />
                                <b-form-invalid-feedback :state="!errors.national_document">
                                    <div v-for="error in errors.national_document" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label>RG:</label>
                            <template v-if="! OpenForm.personalInformation">
                                <p v-if="!state_document">
                                    -
                                </p>
                                <p v-else>{{state_document }}</p>
                            </template>
                            <template v-else>
                                <b-form-input v-model="state_document" type="text" placeholder="RG" trim maxlength="14"></b-form-input>
                                <b-form-invalid-feedback :state="!errors.state_document">
                                    <div v-for="error in errors.state_document" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label>E-mail:</label>
                            <template v-if="! OpenForm.personalInformation">
                                <p v-if="!email">
                                    -
                                </p>
                                <p v-else>{{email}}</p>
                            </template>
                            <template v-else>
                                <b-form-input v-model="email"  placeholder="E-mail" trim></b-form-input>
                                <b-form-invalid-feedback :state="!errors.email">
                                    <div v-for="error in errors.email" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="form-group">
                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label>Data de Nascimento:</label>
                            <template v-if="! OpenForm.personalInformation">
                                <p v-if="!birth">
                                    -
                                </p>
                                <p v-else>{{birth | moment("DD/MM/YYYY") }}</p>
                            </template>
                            <template v-else>
                                <typeable-date placeholder="dd/mm/aaaa" v-model="birth"></typeable-date>
                            </template>
                        </div>
                    </b-col>
                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label>Carteira Profissional:</label>
                            <template v-if="! OpenForm.personalInformation">
                                <p v-if="!work_number">
                                    -
                                </p>
                                <p v-else>{{work_number}}</p>
                            </template>
                            <template v-else>
                                <b-form-input v-model="work_number" type="number" placeholder="Carteira Profissional" trim></b-form-input>
                                <b-form-invalid-feedback :state="!errors.work_number">
                                    <div v-for="error in errors.work_number" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label>Série:</label>
                            <template v-if="! OpenForm.personalInformation">
                                <p v-if="!work_number_serie">
                                    -
                                </p>
                                <p v-else>{{work_number_serie}}</p>
                            </template>
                            <template v-else>
                                <b-form-input v-model="work_number_serie" type="number" placeholder="Serie" trim></b-form-input>
                                <b-form-invalid-feedback :state="!errors.work_number_serie">
                                    <div v-for="error in errors.work_number_serie" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="form-group">
                    <b-col sm="12" md="12" lg="6">
                        <div role="group">
                            <label>Facebook:</label>
                            <template v-if="! OpenForm.personalInformation">
                                <p v-if="!facebook">
                                    -
                                </p>
                                <p v-else>{{facebook}}</p>
                            </template>
                            <template v-else>
                                <b-input-group prepend="facebook.com/">
                                    <b-form-input v-model="facebook"  placeholder="Facebook" trim></b-form-input>
                                </b-input-group>
                                <b-form-invalid-feedback :state="!errors.facebook">
                                    <div v-for="error in errors.facebook" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                    <b-col sm="12" md="12" lg="6">
                        <div role="group">
                            <label>Instagram:</label>
                            <template v-if="! OpenForm.personalInformation">
                                <p v-if="!instagram">
                                    -
                                </p>
                                <p v-else>{{instagram}}</p>
                            </template>
                            <template v-else>
                                <b-input-group prepend="instagram.com/">
                                    <b-form-input v-model="instagram" placeholder="Instagram" trim></b-form-input>
                                </b-input-group>
                                <b-form-invalid-feedback :state="!errors.instagram">
                                    <div v-for="error in errors.instagram" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="form-group">
                    <b-col sm="12" md="6" lg="6">
                        <div role="group">
                            <label>CNH:</label>
                            <template v-if="! OpenForm.personalInformation">
                                <p v-if="!driverlicense">
                                    -
                                </p>
                                <p v-else>{{driverlicense}}</p>
                            </template>
                            <template v-else>
                                <b-form-input v-model="driverlicense" type="number" placeholder="CNH" trim></b-form-input>
                                <b-form-invalid-feedback :state="!errors.driverlicense">
                                    <div v-for="error in errors.driverlicense" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                    <b-col sm="12" md="6" lg="6">
                        <div role="group">
                            <label>Categoria:</label>
                            <template v-if="! OpenForm.personalInformation">
                                <p v-if="!driverlicensetype">
                                    -
                                </p>
                                <p v-else>{{driverlicensetype}}</p>
                            </template>
                            <template v-else>
                                <b-form-select v-model="driverlicensetype" :options="driverlicensetype_options">
                                    <template #first>
                                        <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback :state="!errors.driverlicensetype">
                                    <div v-for="error in errors.driverlicensetype" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="form-group" v-if="status === constants.STATUS_DISAPPROVED">
                    <b-col sm="12" md="12" lg="12">
                        <div role="group">
                            <label>Motivo da reprovação:</label>
                            <template v-if="! OpenForm.personalInformation">
                                <p v-if="!disapproval_reason">
                                    -
                                </p>
                                <b-alert v-else class="text-dark" variant="warning" show>{{disapproval_reason}}</b-alert>
                            </template>
                            <template v-else>
                                <b-form-textarea v-model="disapproval_reason" placeholder="..." rows="4"></b-form-textarea>
                                <b-form-invalid-feedback :state="!errors.disapproval_reason">
                                    <div v-for="error in errors.disapproval_reason" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="IsUpdate && has_permission('worker-edit')">
                    <b-col sm="12" v-if="!IsStage">
                        <b-button v-if="!OpenForm.personalInformation" variant="mary-beleza" size="sm" @click="$store.dispatch('worker/OpenForm', 'personalInformation')">
                            <fa icon="pen" class="mr-2" /> Editar
                        </b-button>
                        <template v-else>
                            <b-button variant="outline-mary-beleza" size="sm" @click="$store.dispatch('worker/CancelEdit')" class="mr-2">
                                <fa icon="ban" class="mr-2" /> Cancelar
                            </b-button>
                            <b-button :disabled="loading" variant="mary-beleza" size="sm" @click="update">
                                <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                            </b-button>
                        </template>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </panel>
    
    <panel v-if="IsUpdate && IsStage && has_permission('worker-edit')">
        <b-row>
            <b-col sm="12">
                <b-button variant="mary-beleza" size="sm" class="btn btn-mary-beleza" @click="update">
                    <b-icon icon="arrow-right-circle" class="mr-2" /> Próximo
                </b-button>
            </b-col>
        </b-row>
    </panel>
</div>
</template>

<script>
import Panel from "@/components/Panel"
import TypeableDate from "@/components/TypeableDate"
import { mapGetters } from 'vuex'
import Constants from '@/constants'

export default {
    components: {
        Panel,
        TypeableDate
    },

    data() {
        return {
            loading: false,
            password_eye: false,
            gender_options: [{
                    text: 'Feminino',
                    value: 'female'
                },
                {
                    text: 'Masculino',
                    value: 'male'
                },
            ],
            marital_options: [
                {
                    text: 'Casado(a)',
                    value: 1
                },
                {
                    text: 'Solteiro(a)',
                    value: 2
                },
                {
                    text: 'Viúvo(a)',
                    value: 3
                },
                {
                    text: 'Divorciado(a)',
                    value: 4
                },
                {
                    text: 'Separado(a)',
                    value: 5
                },
            ],
            driverlicensetype_options: [
                {
                    text: 'A',
                    value: 'A'
                },
                {
                    text: 'B',
                    value: 'B'
                },
                {
                    text: 'AB',
                    value: 'AB'
                },
            ]
        }
    },

    methods: {
        passwordVisibility() {
            this.password_eye = !this.password_eye;
        },

        update() {
            this.loading = true;
            this.$store.dispatch('worker/updateWorker').then(response => {
                if(this.IsStage){this.$store.commit('worker/STAGE_NEXT')}
                this.sweealert2('success', response.success);
                this.loading = false;
            }).catch(error => {
                this.sweealert2('error', error[Object.keys(error)[0]])
                this.loading = false;
            });
        },

        sweealert2(icon, text) {
            this.$swal({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                html: '&nbsp;&nbsp;'+text,
                icon: icon,
                padding: 20,
                background: '#dbf6e9'
            })
        },
    },

    computed: {
        ...mapGetters('worker', [
            'OpenForm', 'IsUpdate', 'IsStage',
        ]),

        ...mapGetters('auth', [
            'has_permission'
        ]),

        constants(){
            return Constants
        },

        status: {
            get() {
                return this.$store.state.worker.worker.status;
            }
        },

        name: {
            get() {
                return this.$store.state.worker.worker.person.name;
            },
            set(value) {
                this.$store.commit('worker/SET_NAME', value)
            }
        },

        email: {
            get() {
                return this.$store.state.worker.worker.person.email;
            },
            set(value) {
                this.$store.commit('worker/SET_EMAIL', value)
            }
        },

        password: {
            get() {
                return this.$store.state.worker.worker.person.password;
            },
            set(value) {
                this.$store.commit('worker/SET_PASSWORD', value)
            }
        },

        facebook: {
            get() {
                return this.$store.state.worker.worker.detail.facebook;
            },
            set(value) {
                this.$store.commit('worker/SET_FACEBOOK', value)
            }
        },

        instagram: {
            get() {
                return this.$store.state.worker.worker.detail.instagram;
            },
            set(value) {
                this.$store.commit('worker/SET_INSTAGRAM', value)
            }
        },

        national_document: {
            get() {
                return this.$store.state.worker.worker.person.national_document;
            },
            set(value) {
                this.$store.commit('worker/SET_NATIONAL_DOCUMENT', value)
            }
        },

        state_document: {
            get() {
                return this.$store.state.worker.worker.person.state_document;
            },
            set(value) {
                this.$store.commit('worker/SET_STATE_DOCUMENT', value)
            }
        },

        driverlicense: {
            get() {
                return this.$store.state.worker.worker.detail.driverlicense;
            },
            set(value) {
                this.$store.commit('worker/SET_DRIVERLICENSE', value)
            }
        },

        driverlicensetype: {
            get() {
                return this.$store.state.worker.worker.detail.driverlicensetype;
            },
            set(value) {
                this.$store.commit('worker/SET_DRIVERLICENSETYPE', value)
            }
        },

        birth: {
            get() {
                return this.$store.state.worker.worker.person.birth;
            },
            set(value) {
                this.$store.commit('worker/SET_BIRTH', value)
            }
        },

        gender: {
            get() {
                return this.$store.state.worker.worker.person.gender;
            },
            set(value) {
                this.$store.commit('worker/SET_GENDER', value)
            }
        },

        marital: {
            get() {
                return this.$store.state.worker.worker.marital;
            },
            set(value) {
                this.$store.commit('worker/SET_MARITAL', value)
            }
        },

        work_number: {
            get() {
                return this.$store.state.worker.worker.detail.work_number;
            },
            set(value) {
                this.$store.commit('worker/SET_WORK_NUMBER', value)
            }
        },

        work_number_serie: {
            get() {
                return this.$store.state.worker.worker.detail.work_number_serie;
            },
            set(value) {
                this.$store.commit('worker/SET_WORK_NUMBER_SERIE', value)
            }
        },

        disapproval_reason: {
            get() {
                return this.$store.state.worker.worker.disapproval_reason;
            },
            set(value) {
                this.$store.commit('worker/SET_DISAPPROVAL_REASON', value)
            }
        },

        errors: {
            get() {
                return this.$store.state.worker.errors;
            },
        },

        marital_name() {
            let marital = this.marital_options.find(marital => marital.value === this.marital);
            return marital?.text
        },

        gender_name() {
            let gender = this.gender_options.find(gender => gender.value === this.gender)
            return gender?.text
        }

    },
}
</script>
