<template>
    <div id="customer-new">
        <util-bar>
            <div>
                <b-row>
                    <b-col sm="12" md='12' lg="12">
                        <b-button variant="outline-mary-beleza" @click="$router.go(-1)">
                            <fa icon="undo" class="mr-2" />Voltar
                        </b-button>
                        <b-button v-if="IsNewCustomer && has_permission('customer-edit')" @click="createCustomer" variant="mary-beleza" class="ml-2">
                            <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </util-bar>
        
        <b-overlay :show="loading_page" no-wrap rounded="sm">
            <template #overlay>
                <div class="text-center">
                    <img :src="RandomImages()" class="vibrate-1" width="40%" />
                    <h6 class="blink-2"><small>Carregando</small></h6>
                </div>
            </template>
        </b-overlay>

        <div class="pr-3 pl-3 pt-3">

            <panel title="INFORMAÇÕES GERAIS" icon="address-card" icon-lib="far">
                <b-row>
                    <b-col md="12">
                        <b-row class="form-group">
                            <b-col sm="6" md="6" lg="6">
                                <div role="group">
                                    <label class="required">Nome:</label>
                                    <template v-if="IsUpdatePersonal || IsNewCustomer">
                                        <b-form-input ref="name" v-model="name" placeholder="Nome"></b-form-input>
                                        <b-form-invalid-feedback :state="!errors.name">
                                            <div v-for="error in errors.name" :key="error">
                                                {{ error }}
                                            </div>
                                        </b-form-invalid-feedback>
                                    </template>
                                    <template v-else>
                                        <p v-if="!name">
                                            <b class="text-danger">-</b>
                                        </p>
                                        <p v-else>{{ name }}</p>
                                    </template>
                                </div>
                            </b-col>

                            <b-col sm="6" md="6" lg="6">
                                <div role="group">
                                    <label>E-mail:</label>
                                    <template v-if="IsUpdatePersonal || IsNewCustomer">
                                        <b-form-input ref="email" v-model="email" placeholder="E-mail"></b-form-input>
                                        <b-form-invalid-feedback :state="!errors.email">
                                            <div v-for="error in errors.email" :key="error">
                                                {{ error }}
                                            </div>
                                        </b-form-invalid-feedback>
                                    </template>
                                    <template v-else>
                                        <p v-if="!email">
                                            <b class="text-danger">-</b>
                                        </p>
                                        <p v-else>{{ email }}</p>
                                    </template>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="form-group">
                            <b-col sm="12" md="4" lg="4">
                                <div role="group">
                                    <label class="required">CPF:</label>
                                    <template v-if="IsUpdatePersonal || IsNewCustomer">
                                        <the-mask ref="national_document" :mask="'###.###.###-##'" class="form-control" v-model="national_document" placeholder="CPF" maxlength="14" />
                                        <b-form-invalid-feedback :state="!errors.national_document">
                                            <div v-for="error in errors.national_document" :key="error">
                                                {{ error }}
                                            </div>
                                        </b-form-invalid-feedback>
                                    </template>
                                    <template v-else>
                                        <p v-if="!national_document">
                                            <b class="text-danger">-</b>
                                        </p>
                                        <p v-else>{{ national_document | formatDocuments }}</p>
                                    </template>
                                </div>
                            </b-col>

                            <b-col sm="12" md="4" lg="4">
                                <div role="group">
                                    <label>RG:</label>
                                    <template v-if="IsUpdatePersonal || IsNewCustomer">
                                        <b-form-input ref="state_document" v-model="state_document"  placeholder="RG" maxlength="14"></b-form-input>
                                        <b-form-invalid-feedback :state="!errors.state_document">
                                            <div v-for="error in errors.state_document" :key="error">
                                                {{ error }}
                                            </div>
                                        </b-form-invalid-feedback>
                                    </template>
                                    <template v-else>
                                        <p v-if="!state_document">
                                            <b class="text-danger">-</b>
                                        </p>
                                        <p v-else>{{ state_document }}</p>
                                    </template>
                                </div>
                            </b-col>

                            <b-col sm="12" md="4" lg="4">
                                <div role="group">
                                    <label>Data de Aniversário:</label>
                                    <template v-if="IsUpdatePersonal || IsNewCustomer">
                                        <typeable-date ref="birth" v-model="birth"/>
                                        <b-form-invalid-feedback :state="!errors.birth">
                                            <div v-for="error in errors.birth" :key="error">
                                                {{ error }}
                                            </div>
                                        </b-form-invalid-feedback>
                                    </template>
                                    <template v-else>
                                        <p v-if="!birth">
                                            <b class="text-danger">-</b>
                                        </p>
                                        <p v-else>{{ birth | moment("DD/MM/YYYY") }}</p>
                                    </template>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="form-group">
                            <b-col>
                                <div role="group">
                                    <label>Como ficou sabendo da existência da Mary Beleza:</label>
                                    <template v-if="IsUpdatePersonal || IsNewCustomer">
                                        <b-form-select ref="existence" v-model="existence" :options="existence_options"></b-form-select>
                                        <b-form-invalid-feedback :state="!errors.existence">
                                            <div v-for="error in errors.existence" :key="error">
                                                {{ error }}
                                            </div>
                                        </b-form-invalid-feedback>
                                    </template>
                                    <template v-else>
                                        <p v-if="!existence">
                                            <b class="text-danger">-</b>
                                        </p>
                                        <p v-else-if="existence == 15">{{ who_existence }}</p>
                                        <p v-else >{{ existence_name(existence) }}</p>
                                    </template>
                                </div>
                            </b-col>  
                            <b-col sm="4" v-if="existence == 15 && (IsUpdatePersonal || IsNewCustomer)">
                                <div role="group">
                                    <label>Qual outro:</label>
                                    <template v-if="IsUpdatePersonal || IsNewCustomer">
                                        <b-form-input ref="who_existence" v-model="who_existence"  placeholder="Qual outro"></b-form-input>
                                        <b-form-invalid-feedback :state="!errors.who_existence">
                                            <div v-for="error in errors.who_existence" :key="error">
                                                {{ error }}
                                            </div>
                                        </b-form-invalid-feedback>
                                    </template>
                                    <template v-else>
                                        <p v-if="!who_existence">
                                            <b class="text-danger">-</b>
                                        </p>
                                        <p v-else>{{ who_existence }}</p>
                                    </template>
                                </div>
                            </b-col>  
                        </b-row>
                        <b-row class="form-group">
                            <b-col sm="12" md="12" lg="12" class="d-flex align-items-center">
                                <div role="group">
                                    <label>
                                        Este cliente aceita receber e-mails de Pós-Venda: 
                                        <b-icon icon="patch-question-fll" class="text-mary-beleza" id="after_sales"></b-icon>
                                    </label>
                                    <b-popover target="after_sales" triggers="hover" placement="top">
                                        <template #title>AJUDA</template>
                                        <div class="text-center">
                                            Quando <strong class="text-uppercase">desativado</strong> o cliente <strong class="text-uppercase">não</strong> receberá e-mail para realização de pós-vendas
                                        </div>
                                    </b-popover>
                                    <template v-if="IsUpdatePersonal || IsNewCustomer">
                                        <b-form-checkbox ref="after_sales" v-model="after_sales" switch size="lg"><small>{{!after_sales ? 'Não' : 'Sim' }}</small></b-form-checkbox>
                                        <b-form-invalid-feedback :state="!errors.after_sales">
                                            <div v-for="error in errors.after_sales" :key="error">
                                                {{ error }}
                                            </div>
                                        </b-form-invalid-feedback>
                                    </template>
                                    <template v-else>
                                        <p>
                                            <b v-if="after_sales == null" class="text-danger">-</b>
                                            <b-badge v-else-if="after_sales" variant="success">Sim</b-badge>
                                            <b-badge v-else variant="danger">Não</b-badge>
                                        </p>
                                    </template>
                                </div>
                            </b-col>
                            <b-col sm="12" md="12" lg="12" class="d-flex align-items-center">
                                <div role="group">
                                    <label>
                                        Este cliente é um parceiro: 
                                        <b-icon icon="patch-question-fll" class="text-mary-beleza" id="after_pather"></b-icon>
                                    </label>
                                    <b-popover target="after_sales" triggers="hover" placement="top">
                                        <template #title>AJUDA</template>
                                        <div class="text-center">
                                            Quando <strong class="text-uppercase">desativado</strong> o cliente <strong class="text-uppercase">não</strong> receberá e-mail para realização de pós-vendas
                                        </div>
                                    </b-popover>
                                    <template v-if="IsUpdatePersonal || IsNewCustomer">
                                        <b-form-checkbox ref="partner" v-model="partner" switch size="lg"><small>{{!partner ? 'Não' : 'Sim' }}</small></b-form-checkbox>
                                        <b-form-invalid-feedback :state="!errors.partner">
                                            <div v-for="error in errors.partner" :key="error">
                                                {{ error }}
                                            </div>
                                        </b-form-invalid-feedback>
                                    </template>
                                    <template v-else>
                                        <p>
                                            <b v-if="partner == null" class="text-danger">-</b>
                                            <b-badge v-else-if="partner" variant="success">Sim</b-badge>
                                            <b-badge v-else variant="danger">Não</b-badge>
                                        </p>
                                    </template>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row class="form-group">
                    <b-col v-if="!IsNewCustomer && has_permission('customer-edit')">
                        <span v-if="IsUpdatePersonal">
                            <b-button v-if="IsUpdatePersonal" @click="updateCustomer" variant="mary-beleza" size="sm" class="mr-2">
                                <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                            </b-button>
                            <b-button size="sm" @click="editCancelCustomer('IsUpdatePersonal')" variant="outline-mary-beleza">
                                <fa icon="ban" class="mr-2" /> Cancelar
                            </b-button>
                        </span>
                        <b-button v-else size="sm"  @click="editCustomer('IsUpdatePersonal')" variant="mary-beleza">
                            <b-icon icon="pen" class="mr-2" /> Editar
                        </b-button>
                    </b-col>
                </b-row>
            </panel>

            <panel title="INFORMAÇÕES DE CONTATO" icon="address-book" icon-lib="far">
                <b-row class="form-group">
                    <b-col md="12">
                        <b-button v-if="has_permission('customer-edit')" variant="primary" size="sm" class="mb-2" :disabled="new_phone && phone_edit_index != null" @click="AddPhones"><fa icon="plus-circle" class="mr-2" /> Adicionar Telefone</b-button>
                        <b-table-simple responsive striped small hover >
                            <b-thead head-variant="light">
                                <b-tr>
                                    <b-th class="text-center required"><label class="required">Telefone</label></b-th>
                                    <b-th class="text-center"><label>Descrição</label></b-th>
                                    <b-th class="text-center"><label>SMS</label></b-th>
                                    <b-th class="text-center"><label>WhatsApp</label></b-th>
                                    <b-th class="text-center" v-if="has_permission('customer-edit')"></b-th>
                                    <b-th class="text-center" v-if="has_permission('customer-edit')"></b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody v-if="phones.length">
                                <b-tr v-for="(phone, index) in phones" :key="index">
                                    <b-td class="align-middle text-center">
                                        <template v-if="index == phone_edit_index || IsNewCustomer">
                                            <the-mask :ref="index+'.phone_number'" :mask="['(##) #####-####', '(##) ####-####']" class="form-control" :class="[errors[index+'.phone_number'] ? 'is-invalid' : '']" v-model="phone.phone_number" @input="UpdateNumber($event, index)" placeholder="Informe um Telefone" />
                                        </template>
                                        <template v-else>
                                            <p>{{ phone.phone_number | formatPhones }}</p>
                                        </template>
                                    </b-td>
                                    <b-td class="align-middle text-center">
                                        <template v-if="index == phone_edit_index || IsNewCustomer">
                                            <b-form-input :ref="index+'.description'" v-model="phone.description" @input="UpdateDescription($event, index)" placeholder="Descrição" maxlength="99" />
                                        </template>
                                        <template v-else>
                                            <p v-if="!phone.description">
                                                <b class="text-danger">-</b>
                                            </p>
                                            <p v-else>{{ phone.description }}</p>
                                        </template>
                                    </b-td>
                                    <b-td class="align-middle text-center">
                                        <template v-if="index == phone_edit_index || IsNewCustomer">
                                            <b-form-checkbox :ref="index+'.sms'" v-model="phone.sms" size="lg" :state="errors[index+'.sms'] ? false : null" switch @change="CheckedSMS(phone, index)"></b-form-checkbox>
                                        </template>
                                        <template v-else>
                                            <b v-if="phone.sms == null" class="text-danger">-</b>
                                            <b-badge v-else-if="phone.sms" variant="success">Sim</b-badge>
                                            <b-badge v-else variant="danger">Não</b-badge>
                                        </template>
                                    </b-td>
                                    <b-td class="align-middle text-center">
                                        <template v-if="index == phone_edit_index || IsNewCustomer">
                                            <b-form-checkbox :ref="index+'.is_whatsapp'" v-model="phone.is_whatsapp" size="lg" :state="errors[index+'.is_whatsapp'] ? false : null" switch @change="CheckedWhatsapp(phone, index)"></b-form-checkbox>
                                        </template>
                                        <template v-else>
                                            <b v-if="phone.is_whatsapp == null" class="text-danger">-</b>
                                            <b-badge v-else-if="phone.is_whatsapp" variant="success">Sim</b-badge>
                                            <b-badge v-else variant="danger">Não</b-badge>
                                        </template>
                                    </b-td>
                                    <b-td class="align-middle text-center" v-if="has_permission('customer-edit')">
                                        <template v-if="!IsNewCustomer">
                                            <b-button v-if="index == phone_edit_index" class="btn btn-mary-beleza" size="sm" @click="new_phone ? SavePhone(index) : UpdatePhone(index)">
                                                <b-icon icon="check" font-scale="1" />
                                            </b-button>
                                            <b-button v-else variant="primary" size="sm" @click="EditPhone(index)" :disabled="new_phone || phone_edit_index != null">
                                                <b-icon icon="pen" font-scale="1" />
                                            </b-button>
                                        </template>
                                    </b-td>
                                    <b-td class="align-middle text-center" v-if="has_permission('customer-edit')">
                                        <b-button v-if="index == phone_edit_index && !IsNewCustomer" variant="danger" size="sm" @click="new_phone ? RemovePhone(index) : CancelPhone(index)">
                                            <b-icon icon="arrow-counterclockwise" font-scale="1" />
                                        </b-button>
                                        <b-button v-else variant="danger" size="sm" @click="RemovePhone(index)" :disabled="new_phone || phone_edit_index != null">
                                            <b-icon icon="trash" font-scale="1" />
                                        </b-button>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                            <b-tbody v-else>
                                <b-tr>
                                    <b-td colspan="5" class="text-center"><small>Nenhum Telefone Adicionado</small></b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
            </panel>

            <panel title="ENDEREÇOS" icon="map-marked-alt">
                <b-row class="form-group">
                    <b-col sm="12" md="12" lg="12">
                        <b-tabs card active-nav-item-class="text-uppercase font-weight-bold">
                            <!-- Render Tabs, supply a unique `key` to each tab -->
                            <b-tab v-for="(address, index) in adresses" :active="(adresses.length - 1) == index ? false : true" :key="'dyn-tab-' + index">
                                <template #title>
                                    <span>Endereço {{ (index + 1) }}</span> <span v-if="address.default" class="text-uppercase">(padrão)</span> <fa icon="times-circle" @click="RemoveAddress(index)" v-if="address_edit_index == null && has_permission('customer-edit') && !address.default"/>
                                </template>
                                
                                <b-row class="form-group text-right">
                                    <b-col sm="12" md="12" lg="12">
                                        <b-button v-if="address.default" disabled size="sm" class="mb-2"><b-icon icon="journal-check" class="mr-2" /> Endereço Padrão</b-button>
                                        <b-button v-else-if="index == address_edit_index && has_permission('customer-edit')" variant="primary" size="sm" class="mb-2" @click="UpdateAddressDefault(true, index)"><b-icon icon="journal-check" class="mr-2" /> Tornar Endereço Padrão</b-button>
                                    </b-col>
                                </b-row>

                                
                                <b-row class="form-group name">
                                    <b-col sm="12" md="12" lg="12">
                                        <label>Identificação do Endereço:</label>
                                    </b-col>
                                    <template v-if="index == address_edit_index || IsNewCustomer">
                                        <b-col sm="12" md="12" lg="12">
                                            <b-form-group>
                                                <button-group :others="true" :options="name_options" :value="address.name" @input="UpdateAddressName($event, index)"></button-group>
                                            </b-form-group>
                                        </b-col>
                                    </template>
                                    <template v-else>
                                        <b-col>
                                            <p v-if="!address.name">
                                                <b class="text-danger">-</b>
                                            </p>
                                            <p v-else>{{ name_address_text(address.name) }}</p>
                                        </b-col>
                                    </template>
                                </b-row>
                                <b-overlay :show="search_zipcode" rounded="sm">
                                    <b-row class="form-group">
                                        <b-col sm="12" md="6" lg="6">
                                            <b-row class="form-group">
                                                <b-col sm="12" md="12" lg="12">
                                                    <div role="group">
                                                        <label class="required">CEP:</label>
                                                        <template v-if="index == address_edit_index || IsNewCustomer">
                                                            <the-mask :ref="index+'.zipcode'" :mask="'#####-###'" class="form-control" v-model="address.zipcode" placeholder="CEP" @input="UpdateAddressZipcode($event, index)" @blur.native="SearchZipcode(index)" />
                                                            <b-form-invalid-feedback :state="!errors[index+'.zipcode']">
                                                                <div v-for="error in errors[index+'.zipcode']" :key="error">
                                                                    {{ error }}
                                                                </div>
                                                            </b-form-invalid-feedback>
                                                        </template>
                                                        <template v-else>
                                                            <p v-if="!address.zipcode">
                                                                <b class="text-danger">-</b>
                                                            </p>
                                                            <p v-else>{{ address.zipcode | formatCep }}</p>
                                                        </template>
                                                    </div>
                                                </b-col>
                                            </b-row>

                                            <b-row class="form-group">
                                                <b-col sm="12" md="12" lg="12">
                                                    <div role="group">
                                                        <label class="required">Logradouro:</label>
                                                        <template v-if="index == address_edit_index || IsNewCustomer">
                                                            <b-form-input :ref="index+'.street'" v-model="address.street"  @input="UpdateAddressStreet($event, index)" @blur.native="SearchLocation(index)" placeholder="Logradouro"></b-form-input>
                                                            <b-form-invalid-feedback :state="!errors[index+'.street']">
                                                                <div v-for="error in errors[index+'.street']" :key="error">
                                                                    {{ error }}
                                                                </div>
                                                            </b-form-invalid-feedback>
                                                        </template>
                                                        <template v-else>
                                                            <p v-if="!address.street">
                                                                <b class="text-danger">-</b>
                                                            </p>
                                                            <p v-else>{{ address.street }}</p>
                                                        </template>
                                                    </div>
                                                </b-col>
                                            </b-row>

                                            <b-row class="form-group">
                                                <b-col sm="12" md="5" lg="5">
                                                    <div role="group">
                                                        <label class="required">Número:</label>
                                                        <template v-if="index == address_edit_index || IsNewCustomer">
                                                            <b-form-input :ref="index+'.number'" v-model="address.number" @input="UpdateAddressNumber($event, index)" @blur.native="SearchLocation(index)" placeholder="Número"></b-form-input>
                                                            <b-form-invalid-feedback :state="!errors[index+'.number']">
                                                                <div v-for="error in errors[index+'.number']" :key="error">
                                                                    {{ error }}
                                                                </div>
                                                            </b-form-invalid-feedback>
                                                        </template>
                                                        <template v-else>
                                                            <p v-if="!address.number">
                                                                <b class="text-danger">-</b>
                                                            </p>
                                                            <p v-else>{{ address.number }}</p>
                                                        </template>
                                                    </div>
                                                </b-col>

                                                <b-col sm="12" md="7" lg="7">
                                                    <div role="group">
                                                        <label>Complemento:</label>
                                                        <template v-if="index == address_edit_index || IsNewCustomer">
                                                            <b-form-input :ref="index+'.complement'" v-model="address.complement" @input="UpdateAddressComplement($event, index)" placeholder="Complemento"></b-form-input>
                                                            <b-form-invalid-feedback :state="!errors[index+'.complement']">
                                                                <div v-for="error in errors[index+'.complement']" :key="error">
                                                                    {{ error }}
                                                                </div>
                                                            </b-form-invalid-feedback>
                                                        </template>
                                                        <template v-else>
                                                            <p v-if="!address.complement">
                                                                <b class="text-danger">-</b>
                                                            </p>
                                                            <p v-else>{{ address.complement }}</p>
                                                        </template>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                            
                                            <b-row class="form-group">
                                                <b-col sm="12" md="12" lg="12">
                                                    <div role="group">
                                                        <label class="required">Bairro:</label>
                                                        <template v-if="index == address_edit_index || IsNewCustomer">
                                                            <b-form-input :ref="index+'.district'" v-model="address.district" @input="UpdateAddressDistrict($event, index)" @blur.native="SearchLocation(index)" placeholder="Bairro"></b-form-input>
                                                            <b-form-invalid-feedback :state="!errors[index+'.district']">
                                                                <div v-for="error in errors[index+'.district']" :key="error">
                                                                    {{ error }}
                                                                </div>
                                                            </b-form-invalid-feedback>
                                                        </template>
                                                        <template v-else>
                                                            <p v-if="!address.district">
                                                                <b class="text-danger">-</b>
                                                            </p>
                                                            <p v-else>{{ address.district }}</p>
                                                        </template>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                            <b-row class="form-group">
                                                <b-col sm="12" md="4" lg="12">
                                                    <div role="group">
                                                        <label>Zona:</label>
                                                        <template v-if="index == address_edit_index || IsNewCustomer">
                                                            <b-form-select :ref="index+'.zone'" v-model="address.zone" :options="zone_options" @input="UpdateAddressZone($event, index)">
                                                                <template #first>
                                                                    <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                            <b-form-invalid-feedback :state="!errors[index+'.zone']">
                                                                <div v-for="error in errors[index+'.zone']" :key="error">
                                                                    {{ error }}
                                                                </div>
                                                            </b-form-invalid-feedback>
                                                        </template>
                                                        <template v-else>
                                                            <p v-if="!zone_name(address.zone)">
                                                                <b class="text-danger">-</b>
                                                            </p>
                                                            <p v-else>{{ zone_name(address.zone) }}</p>
                                                        </template>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-col>

                                        <b-col sm="12" md="6" lg="6" class="address-map">
                                            <b-icon icon="patch-question-fll" class="text-mary-beleza" scale="2" id="address-map-help"></b-icon>
                                            <b-popover target="address-map-help" triggers="hover" placement="leftbottom" custom-class="address-map-help">
                                                <template #title>AJUDA</template>
                                                <p>
                                                    Verifique se o endereço do cliente está com a localização correta e
                                                    se não estiver, tente alterar a forma como o endereço foi escrito.
                                                </p>
                                                <p>
                                                    A informação presente no mapa será utilizada para geolocalização 
                                                    dos clientes no mapa de clientes e também pelas profissionais 
                                                    por meio do aplicativo Mary Beleza Profissionais.
                                                </p>
                                            </b-popover>
                                            <b-overlay :show="loading_location" v-if="address.latitude && address.longitude">
                                                <iframe class="w-100"
                                                    height="400"
                                                    frameborder="0" style="border:0"
                                                    :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyDwoQFq-k2HwjpLJK59QyWO7hL3kVjierY&q='+address.latitude+','+address.longitude" allowfullscreen>
                                                </iframe>
                                            </b-overlay>
                                        </b-col>
                                    </b-row>
                                    <b-row class="form-group">
                                        <b-col sm="12" md="4" lg="4">
                                            <div role="group">
                                                <label>Ponto de Referência:</label>
                                                <template v-if="index == address_edit_index || IsNewCustomer">
                                                    <b-form-input :ref="index+'.reference'" v-model="address.reference" @input="UpdateAddressReference($event, index)" placeholder="Ponto de Referência"></b-form-input>
                                                    <b-form-invalid-feedback :state="!errors[index+'.reference']">
                                                        <div v-for="error in errors[index+'.reference']" :key="error">
                                                            {{ error }}
                                                        </div>
                                                    </b-form-invalid-feedback>
                                                </template>
                                                <template v-else>
                                                    <p v-if="!address.reference">
                                                        <b class="text-danger">-</b>
                                                    </p>
                                                    <p v-else>{{ address.reference }}</p>
                                                </template>
                                            </div>
                                        </b-col>
                                        <b-col sm="12" md="4" lg="4">
                                            <div role="group">
                                                <label class="required">Estado:</label>    
                                                <template v-if="index == address_edit_index || IsNewCustomer">
                                                    <b-form-select v-model="address.city.state.id" :options="states" @input="UpdateAddressState($event, index)">
                                                        <template #first>
                                                            <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                </template>
                                                <template v-else>
                                                    <p>{{address.city.state.name}}</p>
                                                </template>
                                            </div>
                                        </b-col>
                                        <b-col sm="12" md="4" lg="4">
                                            <div role="group">
                                                <label class="required">Cidade:</label>
                                                <template v-if="index == address_edit_index || IsNewCustomer">
                                                    <b-form-select :disabled="!address.city.state.id" :ref="index+'.city_id'" v-model="address.city.id" :options="cities" @input="UpdateAddressCity_id($event, index)">
                                                        <template #first>
                                                            <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <b-form-invalid-feedback :state="!errors[index+'.city_id']">
                                                        <div v-for="error in errors[index+'.city_id']" :key="error">
                                                            {{ error }}
                                                        </div>
                                                    </b-form-invalid-feedback>
                                                </template>
                                                <template v-else>
                                                    <p>{{address.city.name}}</p>
                                                </template>
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <b-row v-if="!IsNewCustomer && has_permission('customer-edit')">
                                        <b-button v-if="index == address_edit_index" variant="mary-beleza" size="sm" @click="new_address ? SaveAddress(index) : UpdateAddress(index)" class="mr-2">
                                            <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                                        </b-button>
                                        <b-button v-else size="sm" variant="mary-beleza"  @click="EditAddress(index)" class="mr-2">
                                            <fa icon="pen" class="mr-2" /> Editar
                                        </b-button>
                                        <b-button v-if="index == address_edit_index" variant="outline-mary-beleza" size="sm" @click="new_address ? RemoveAddress(index) : CancelAddress(index)" class="mr-2">
                                            <fa icon="ban" class="mr-2" /> Cancelar
                                        </b-button>
                                    </b-row>
                                </b-overlay>
                            </b-tab>

                            <!-- New Tab Button (Using tabs-end slot) -->
                            <template #tabs-end v-if="has_permission('customer-edit')">
                                <b-nav-item v-if="address_edit_index == null || IsNewCustomer" role="presentation" @click.prevent="AddAddress" href="#"><fa icon="plus-circle" /></b-nav-item>
                            </template>

                            <!-- Render this if no tabs -->
                            <template #empty>
                                <div class="text-center text-muted">
                                    Nenhum Endereço encontrado<br>
                                    Adicione um novo endereço clicando no botão <fa icon="plus-circle" /> acima.
                                </div>
                            </template>
                        </b-tabs>
                    </b-col>
                </b-row>
            </panel>

            <panel title="OUTRAS INFORMAÇÕES" icon="align-left">
                <b-row class="form-group">
                    <b-col sm="12" md="12" lg="12">
                        <label>Observações sobre o cliente:</label>
                        <template v-if="IsUpdateInformations || IsNewCustomer">
                            <b-form-textarea ref="observations" v-model="observations" placeholder="Observações sobre o cliente" rows="3" max-rows="6" ></b-form-textarea>
                            <b-form-text id="observations">
                            Esse campo se refera a observações gerais sobre o cliente. <strong>NÃO DEVE</strong> ser utilizado para informações 
                            específicas sobre um serviço solicitado. Este tipo de informação específica deverá ser inserido em cada 
                            serviço solicitado.
                            </b-form-text>
                        </template>
                        <template v-else>
                            <p v-if="!observations">
                                <b class="text-danger">-</b>
                            </p>
                            <p v-else>{{ observations }}</p>
                        </template>
                    </b-col>
                </b-row>
                <b-row class="form-group">
                    <b-col sm="12" md="4" lg="7">
                        <div role="group">
                            <label>
                                Cliente em restrição: 
                                <b-icon icon="patch-question-fll" class="text-mary-beleza" id="restriction_customer"></b-icon>
                            </label>
                            
                            <b-popover target="restriction_customer" triggers="hover" placement="rightbottom">
                                <template #title>AJUDA</template>
                                <div class="text-center">
                                    Este campo serve para marcar um cliente em restrição, descrevendo que este teve algum problema durante 
                                    algum serviço prestado pela Mary Beleza. Por exemplo, aqueles casos em que o cliente tenta contratar a 
                                    profissional "por fora", sem ter mais contato com a Mary Beleza.
                                </div>
                            </b-popover>
                            <template v-if="IsUpdateInformations || IsNewCustomer">
                                <b-form-checkbox v-model="restriction" switch size="lg"><small>{{!restriction ? 'Não' : 'Sim' }}</small></b-form-checkbox>
                                <b-form-invalid-feedback :state="!errors.restriction">
                                    <div v-for="error in errors.restriction" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                            <template v-else>
                                <p>
                                    <b v-if="restriction == null" class="text-danger">-</b>
                                    <b-badge v-else-if="restriction" variant="success">Sim</b-badge>
                                    <b-badge v-else variant="danger">Não</b-badge>
                                </p>
                            </template>
                            
                        </div>
                    </b-col>
                    <b-col sm="12" md="4" lg="5" v-if="restriction">
                        <div role="group">
                            <label> Gravidade: </label>   
                            <template v-if="IsUpdateInformations || IsNewCustomer">                     
                                <b-form-select v-model="restriction_level" :options="restriction_level_options">
                                    <template #first>
                                        <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback :state="!errors.restriction_level">
                                    <div v-for="error in errors.restriction_level" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                            <template v-else>
                                <p v-if="!restriction_level">
                                    <b class="text-danger">-</b>
                                </p>
                                <p v-else>{{ restriction_level_name(restriction_level) }}</p>
                            </template>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="form-group" v-if="restriction">
                    <b-col sm="12" md="12" lg="12">
                        <div role="group">
                            <label>
                                Motivo/Observações: 
                            </label> 
                            <template v-if="IsUpdateInformations || IsNewCustomer">                      
                                <b-form-textarea ref="restrict_reason" v-model="restrict_reason" placeholder="Descreva o motivo/observações do problema" rows="3" max-rows="6" ></b-form-textarea>
                                <b-form-invalid-feedback :state="!errors.restrict_reason">
                                        <div v-for="error in errors.restrict_reason" :key="error">
                                            {{ error }}
                                        </div>
                                </b-form-invalid-feedback>
                            </template>
                            <template v-else>
                                <p v-if="!restrict_reason">
                                    <b class="text-danger">-</b>
                                </p>
                                <p v-else>{{ restrict_reason }}</p>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="form-group" v-if="!IsNewCustomer && has_permission('customer-edit')">
                    <b-col>
                        <span v-if="IsUpdateInformations">
                            <b-button @click="updateCustomer" size="sm" variant="mary-beleza" class="mr-2">
                                <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                            </b-button>
                            <b-button size="sm" @click="editCancelCustomer('IsUpdateInformations')" variant="outline-mary-beleza">
                                <fa icon="ban" class="mr-2" /> Cancelar
                            </b-button>
                        </span>
                        <b-button v-else size="sm" @click="editCustomer('IsUpdateInformations')" variant="mary-beleza">
                            <b-icon icon="pen" class="mr-2" /> Editar
                        </b-button>
                    </b-col>
                </b-row>
            </panel>
        
        </div>
    </div>
</template>

<script>
import Panel from '@/components/Panel'
import CustomerDomain from '@/domain/customer'
import ButtonGroup from '@/components/ButtonGroup'
import UtilBar from '@/components/UtilBar'
import _cloneDeep from 'lodash/cloneDeep'
import TypeableDate from '@/components/TypeableDate'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            IsUpdatePersonal: false,
            IsUpdateInformations: false,
            IsNewCustomer: false,
            existence_options: [
                { value: null, text: 'Selecione', disabled: true },
                { value: 1, text: 'Bloguerias' },
                { value: 2, text: 'Cartão de Visita' },
                { value: 3, text: 'E-mail Marketing' },
                { value: 4, text: 'Facebook' },
                { value: 5, text: 'Google' },
                { value: 6, text: 'Instagram' },
                { value: 7, text: 'Indicação de Amigos' },
                { value: 8, text: 'Jornal' },
                { value: 9, text: 'Outdoor' },
                { value: 10, text: 'Panfletos de Propaganda' },
                { value: 11, text: 'Revistas' },
                { value: 12, text: 'SMS' },
                { value: 13, text: 'WhatsApp' },
                { value: 14, text: '', disabled: true },
                { value: 15, text: 'Outros' }
            ],
            name_options: [
                { id: 1, text: "Casa", icon: 'house-door' },
                { id: 2, text: "Trabalho", icon: 'briefcase' },
                { id: -2, text: "Outro", icon: 'cone-striped' }
            ],
            restriction_level_options: [
                { value: 1, text: 'Leve' },
                { value: 2, text: 'Média' },
                { value: 3, text: 'Grave'}
            ],
            zone_options: [
                { text: 'Centro', value: 0 },
                { text: 'Norte', value: 1 },
                { text: 'Sul', value: 2 }, 
                { text: 'Leste', value: 3 },
                { text: 'Oeste', value: 4 },
            ],
            states: [],
            cities: [],
            search_zipcode: false,
            loading_location: false,
            loading_page: false,
            phone_edit_index: null,
            new_phone: false,
            address_edit_index: null,
            new_address: false,
            customerDb: {},
            phoneDb: {},
            addressDb: {},
            loaderImages: [
                'icon-nails', 
                'icon-hair-stylist', 
                'icon-massage', 
                'icon-lipstick'
            ]
        }
    },
    components: {
        Panel, 
        ButtonGroup, 
        UtilBar,
        TypeableDate
    },

    created() {

        this.setCustomer(new CustomerDomain())
        this.$store.commit('customer/SET_CLEAR_ERROR');
        
        if (this.$route.params.id) {
            this.loading_page = true;
            this.loadIsUpdate(this.$route.params.id)
        }else{
            this.IsNewCustomer = true
        }

        this.$store.dispatch('states/getStates').then(data => {
            data.forEach(state => {
                this.states = [...this.states, {
                    text: state.name,
                    value: state.id
                }]
            });
        });
        
    },

    methods: {
        editCustomer(panel){
            this.customerDb = _cloneDeep(this.customer)
            switch (panel) {
                case 'IsUpdatePersonal':
                    this.IsUpdatePersonal = true
                    break;
            
                case 'IsUpdateInformations':
                    this.IsUpdateInformations = true
                    break;
            }
        },

        editCancelCustomer(panel){
            this.setCustomer(this.customerDb)
            switch (panel) {
                case 'IsUpdatePersonal':
                    this.IsUpdatePersonal = false
                    break;
            
                case 'IsUpdateInformations':
                    this.IsUpdateInformations = false
                    break;
            }
        },
        
        createCustomer() {
            this.loading_page = true;

            if(this.verifyDocument()){
                this.$store.dispatch('customer/createCustomer').then(response => {
                    this.sweealert2('success', response.success)
                    this.$router.push('/clientes');
                    this.loading_page = false;
                }).catch(error => {
                    this.showError(error.response.data)
                });
            }
        },

        updateCustomer() {
            this.loading_page = true;
            if(this.verifyDocument()){
                this.$store.dispatch('customer/updateCustomer').then(response => {
                    this.sweealert2('success', response.success)
                    this.IsUpdatePersonal = false
                    this.IsUpdateInformations = false
                }).catch(error => {
                    this.showError(error.response.data)
                });
            }
            this.loading_page = false;
        },

        verifyDocument(){

            var result = false
            var error = {};

            this.customer.person.name === '' || this.customer.person.name === null ? error = Object.assign(error, {
                name: ['O campo nome é obrigatório']
            }) : null

            /* this.customer.person.email === '' || this.customer.person.email === null ? error = Object.assign(error, {
                email: ['O campo e-mail é obrigatório']
            }) : null */

            this.customer.person.national_document === '' || this.customer.person.national_document === null ? error = Object.assign(error, {
                national_document: ['O campo CPF é obrigatório']
            }) : null

            if (Object.keys(error).length === 0) {
                this.$store.commit('customer/SET_ADD_ERROR', {})
                result = true
            } else {
                this.$store.commit('customer/SET_ADD_ERROR', error)
                var err = Object.keys(error)[0]
                this.$refs[err].$el.focus()
                this.sweealert2('error', error[err])
                result = false
            }

            return result
        },

        loadIsUpdate(id){
            this.$store.dispatch('customer/getCustomerById', id).then(() => {
                this.loading_page = false
            }).catch(() => {
                this.loading_page = false
            });
        },

        setCustomer(customer){
            this.$store.commit('customer/SET_CUSTOMER', customer)
        },

        existence_name(id){
            var existence = this.existence_options.find(item => item.value == id)
            return existence?.text
        },

        restriction_level_name(id){
            var restriction_level = this.restriction_level_options.find(item => item.value == id)
            return restriction_level?.text
        },
        AddPhones() {
            this.$store.commit('customer/SET_PHONE_ADD')
            if (!this.IsNewCustomer) {
                this.phone_edit_index = this.phones.length - 1
                this.new_phone = true
            }
        },

        RemovePhone(index) {
            if (!this.IsNewCustomer && !this.new_phone) {
                this.$swal({
                    title: 'Tem certeza que deseja excluir?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Sim',
                    denyButtonText: 'Não',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('customer/deletePhone', index).then(response => {
                            this.sweealert2Toast('success', response.success)
                        }).catch(error => {
                            this.sweealert2Toast('error', error.response.data)
                        });
                    }
                })
            } else {
                this.$store.commit("customer/SET_PHONE_DELETE", index);
            }
            this.new_phone = false
            this.phone_edit_index = null

        },

        SavePhone(index) {
            this.$store.dispatch('customer/createPhone', index).then(response => {
                this.phone_edit_index = null
                this.new_phone = false
                this.sweealert2Toast('success', response.success)
            }).catch(error => {
                var err = Object.keys(error)[0]
                this.$refs[index+'.'+err][0].$el.focus()
                this.sweealert2('error', error[err])
            });
        },

        UpdatePhone(index) {
            this.$store.dispatch('customer/updatePhone', index).then(response => {
                this.phone_edit_index = null
                this.sweealert2Toast('success', response.success)
            }).catch(error => {
                var err = Object.keys(error)[0]
                this.$refs[index+'.'+err][0].$el.focus()
                this.sweealert2('error', error[err])
            });
        },

        EditPhone(index) {
            this.phone_edit_index = index
            this.phoneDb = _cloneDeep(this.phones[index])
        },

        CancelPhone(index) {
            this.phone_edit_index = null
            this.phones[index] = this.phoneDb
        },

        zone_name(value) {
            var data = this.zone_options.find(zone => zone.value === value);
            return data?.text;
        },

        AddAddress() {
            this.$store.commit('customer/SET_ADDRESS_ADD')
            if (!this.IsNewCustomer) {
                this.address_edit_index = this.adresses.length - 1
                this.new_address = true
            }
        },

        RemoveAddress(index) {
            if (!this.IsNewCustomer && !this.new_address) {
                this.$swal({
                    title: 'Tem certeza que deseja excluir?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Sim',
                    denyButtonText: 'Não',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('customer/deleteAddress', index).then(response => {
                            this.sweealert2Toast('success', response.success)
                        }).catch(error => {
                            this.sweealert2Toast('error', error.response.data)
                        });
                    }
                })
            } else {
                this.$store.commit("customer/SET_ADDRESS_DELETE", index);
            }
            this.new_address = false
            this.address_edit_index = null

        },

        SaveAddress(index) {
            this.$store.dispatch('customer/createAddress', index).then(response => {
                this.address_edit_index = null
                this.new_address = false
                this.sweealert2Toast('success', response.success)
            }).catch(error => {
                var err = Object.keys(error)[0]
                this.$refs[index+'.'+err][0].$el.focus()
                this.sweealert2('error', error[err])
            });
        },

        UpdateAddress(index) {
            this.$store.dispatch('customer/updateAddress', index).then(response => {
                this.address_edit_index = null
                this.sweealert2Toast('success', response.success)
            }).catch(error => {
                var err = Object.keys(error)[0]
                this.$refs[index+'.'+err][0].$el.focus()
                this.sweealert2('error', error[err])
            });
        },

        EditAddress(index) {
            this.address_edit_index = index
            this.getCities(this.customer.person.address[index].city.state.id)
            this.addressDb = _cloneDeep(this.adresses[index])
        },

        CancelAddress(index) {
            this.address_edit_index = null
            this.adresses[index] = this.addressDb
        },

        getCities(state_id) {
            this.$store.dispatch('cities/getCities', state_id).then(data => {
                this.cities = [];
                data.forEach(citie => {
                    this.cities = [...this.cities, {
                        text: citie.name,
                        value: citie.id
                    }]
                });
            });
        },

        UpdateAddressState(value, index) {
            var data = {value, index}
            this.$store.commit('customer/SET_ADDRESS_STATE_ID', data)
            if(value != null){
                this.getCities(value);
            }
        },

        name_address_text(name){
            if(name == 1 || name == 2)
                return this.name_options.find(item => item.id == name).text
            else
                return name
        },

        SearchZipcode(index) {
            if(this.adresses[index].zipcode){
                this.search_zipcode = true
                this.$store.dispatch('customer/searchZipcode', index).then(() => {
                    this.SearchLocation(index)
                    this.search_zipcode = false
                }).catch(error => {
                    this.sweealert2('error', error)
                    this.search_zipcode = false
                })
            }
        },  
        SearchLocation(index) {
            if(this.adresses[index].city_id != null){
                this.loading_location = true
                this.$store.dispatch('customer/searchLocation', index).then(() => {
                    this.loading_location = false
                }).catch(() => {
                    this.loading_location = false
                })
            }
        },
        CheckedSMS(phone, index) {
            var data = {phone, index}
            this.$store.commit('customer/SET_PHONE_SMS', data)
        },
        CheckedWhatsapp(phone, index) {
            var data = {phone, index}
            this.$store.commit('customer/SET_PHONE_WHATSAPP', data)
        },
        UpdateNumber(value, index) {
            var data = {value, index}
            this.$store.commit('customer/SET_PHONE_NUMBER', data)
        },
        UpdateDescription(value, index) {
            var data = {value, index}
            this.$store.commit('customer/SET_PHONE_DESCRIPTION', data)
        },
        
        UpdateAddressName(value, index) {
            var data = {value, index}
            this.$store.commit('customer/SET_ADDRESS_NAME', data)
        },
        UpdateAddressZipcode(value, index) {
            var data = {value, index}
            this.$store.commit('customer/SET_ADDRESS_ZIPCODE', data)
        },
        UpdateAddressStreet(value, index) {
            var data = {value, index}
            this.$store.commit('customer/SET_ADDRESS_STREET', data)
        },
        UpdateAddressNumber(value, index) {
            var data = {value, index}
            this.$store.commit('customer/SET_ADDRESS_NUMBER', data)
        },
        UpdateAddressComplement(value, index) {
            var data = {value, index}
            this.$store.commit('customer/SET_ADDRESS_COMPLEMENT', data)
        },
        UpdateAddressDistrict(value, index) {
            var data = {value, index}
            this.$store.commit('customer/SET_ADDRESS_DISTRICT', data)
        },
        UpdateAddressReference(value, index) {
            var data = {value, index}
            this.$store.commit('customer/SET_ADDRESS_REFERENCE', data)
        },
        UpdateAddressCity_id(value, index) {
            this.SearchLocation(index)
            var data = {value, index}
            this.$store.commit('customer/SET_ADDRESS_CITY_ID', data)
        },
        UpdateAddressZone(value, index) {
            var data = {value, index}
            this.$store.commit('customer/SET_ADDRESS_ZONE', data)
        },
        UpdateAddressDefault(value, index) {
            var data = {value, index}
            this.$store.commit('customer/SET_ADDRESS_DEFAULT', data)
        },
        
        showError(error){
            var err = Object.keys(error)[0]
            isNaN(err[0]) ? this.$refs[err].focus() : this.$refs[err][0].$el.focus()
            this.sweealert2('error', error[err])
        },

        sweealert2Toast(icon, text) {
            this.$swal({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                html: '&nbsp;&nbsp;'+text,
                icon: icon,
                padding: 20,
                background: '#dbf6e9'
            })
        },

        sweealert2(icon, text) {
            this.$swal({
                position: 'center',
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonText: 'Ok',
                html: '&nbsp;&nbsp;'+text,
                icon: icon,
                padding: 20,
            })
        },

        RandomImages() {
            return require('@/assets/images/icons/' + this.loaderImages[Math.floor(Math.random() * this.loaderImages.length)] + '.png');
        },
    },

    computed: {

        ...mapGetters('auth', [
            'has_permission'
        ]),

        name: {
            get() { return this.$store.state.customer.customer.person.name },
            set(value) { this.$store.commit('customer/SET_NAME', value)}
        },
        email: {
            get() { return this.$store.state.customer.customer.person.email },
            set(value) { this.$store.commit('customer/SET_EMAIL', value) }
        },
        national_document: {
            get() { return this.$store.state.customer.customer.person.national_document },
            set(value) { this.$store.commit('customer/SET_NATIONAL_DOCUMENT', value) }
        },
        state_document: {
            get() { return this.$store.state.customer.customer.person.state_document },
            set(value) { this.$store.commit('customer/SET_STATE_DOCUMENT', value) }
        },
        birth: {
            get() { return this.$store.state.customer.customer.person.birth },
            set(value) { this.$store.commit('customer/SET_BIRTH', value) }
        },
        existence: {
            get() { return this.$store.state.customer.customer.person.existence },
            set(value) { this.$store.commit('customer/SET_EXISTENCE', value) }
        },
        who_existence: {
            get() { return this.$store.state.customer.customer.person.who_existence },
            set(value) { this.$store.commit('customer/SET_WHO_EXISTENCE', value) }
        },
        observations: {
            get() { return this.$store.state.customer.customer.observations },
            set(value) { this.$store.commit('customer/SET_OBSERVATIONS', value) }
        },
        after_sales: {
            get() { return this.$store.state.customer.customer.after_sales },
            set(value) { this.$store.commit('customer/SET_AFTER_SALES', value) }
        },
        partner: {
            get() { return this.$store.state.customer.customer.partner },
            set(value) { this.$store.commit('customer/SET_PARTNER', value) }
        },
        restriction: {
            get() { return this.$store.state.customer.customer.person.restriction },
            set(value) { this.$store.commit('customer/SET_RESTRICTION', value) }
        },
        restrict_reason: {
            get() { return this.$store.state.customer.customer.person.restrict_reason },
            set(value) { this.$store.commit('customer/SET_RESTRICT_REASON', value) }
        },
        restriction_level: {
            get() { return this.$store.state.customer.customer.person.restriction_level },
            set(value) { this.$store.commit('customer/SET_RESTRICTION_LEVEL', value) }
        },

        
        customer: {
            get() {
                return this.$store.state.customer.customer;
            }
        },

        adresses: {
            get() {
                return this.$store.state.customer.customer.person.address;
            }
        },

        phones: {
            get() {
                return this.$store.state.customer.customer.person.phones;
            }
        },

        errors: {
            get() {
                return this.$store.state.customer.errors;
            }
        },
    }
}
</script>

<style>

</style>