<template>
    <div>
        <b-modal ok-title="Fechar" ok-only id="prepaidcard" title="Solicitação de Cartão">
            <b-form-group>
                <b-input-group size="sm" class="mb-2">
                    <template #append>
                        <b-button class='text-center' variant='primary' @click="CopyPrepadCardURL()">
                            <fa :icon="['fa', 'clone']"/>
                        </b-button>
                    </template>
                    <b-form-input v-model="prepaidcard_url" readonly aria-label="Small text input with custom switch"></b-form-input>
                </b-input-group>
            </b-form-group>
        </b-modal>
        <util-bar>
            <div>
                <b-row>
                    <b-col sm="12" md='12' lg="12" v-if="has_permission('customer-edit')">
                        <router-link to="/clientes/cadastro" class="btn btn-mary-beleza">
                            <fa icon="plus-circle" class="mr-2" /> Adicionar
                        </router-link> 
                    </b-col>
                </b-row>
            </div>
        </util-bar>

        <div class="pr-3 pl-3 pt-3">
            <panel title="Filtros" icon="filter">
                <b-row class="no-print">
                    <b-col sm="12" md="12" lg="12" class="form-group">
                        <label>Inativo a</label>
                        <b-input v-model="filters.inactive" @input="InactiveFilter()" type="number" placeholder="Informe a Quantidade de dias Desejado"></b-input>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" md="12" lg="12" class="text-right form-group">
                        <b-button variant="outline-mary-beleza" @click="CleanFilters">
                            <b-icon class="mr-2" icon="funnel" />
                            <span>Limpar Filtros</span>
                        </b-button>
                    </b-col>
                </b-row>
            </panel>
            <panel>
                <b-row class="form-group">
                    <b-col md="12 text-center">
                        <!-- INSIRA O CONTEÚDO DO PAINEL AQUI -->
                        <b-row class="form-group">
                            <b-col sm="12" md="12" lg="12">
                                <rich-table ref="customersRichTable" v-on:input="ChangeTableConfig" :numbers="table_numbers"
                                    :loading="loadingData" :cols="table_cols" :vueComponent="vue_component">
                                    <template v-if="items && items.length > 0">
                                        <tr v-for="(item, key) in items" v-bind:key="key"
                                            :class="item.page + (item.selected ? ' selected' : '')">
                                            <td>{{item.name}} <strong class="text-warning" style="font-size: 11px;" v-if="!item.exists_schedules"><br>Sem agendamentos</strong></td>
                                            <td>{{item.email}}</td>
                                            <td>{{item.national_document | formatDocuments}}</td>
                                            <td>
                                                <span v-for="(phone, key) in item.phones" v-bind:key="key">
                                                    {{phone.phone_number | formatPhones}} 
                                                    <span v-if="phone.is_whatsapp">
                                                        <a :href="`https://wa.me/55${phone.phone_number}`" target="_blank">
                                                            <fa class="text-success" :icon="['fab', 'whatsapp']" />
                                                        </a>
                                                    </span>
                                                    <span v-if="key < item.phones.length -1"> | </span>
                                                </span>
                                            </td>
                                            <td width="5%" class="text-center">
                                                <b-button
                                                    variant="success"
                                                    size="sm"
                                                    @click="
                                                    $router.push({
                                                    path: '/clientes/historico/' + item.id
                                                    })
                                                    "
                                                    v-b-tooltip
                                                    title="Histórico do Cliente"
                                                >
                                                <b-icon icon="calendar4-week" font-scale="1" />
                                                </b-button>
                                            </td>
                                            <td width="5%" class="text-center">
                                                <b-button v-if="item.blocked == 0 && has_permission('customer-block')" variant="warning" size="sm" @click="blockedCustomer(item)" v-b-tooltip title="Bloquear">
                                                    <b-icon icon="unlock" font-scale="1" />
                                                </b-button>

                                                <b-button v-else-if="item.blocked == 1 && has_permission('customer-block')" variant="danger" size="sm" @click="blockedCustomer(item)" v-b-tooltip title="Desbloquear">
                                                    <b-icon icon="lock" font-scale="1" />
                                                </b-button>
                                            </td>
                                            <td width="5%" class="text-center">
                                                <b-button variant="info" size="sm" @click="changePassword(item)" v-b-tooltip title="Solicitar Criação de Senha Para o App">
                                                    <b-icon icon="key-fill" font-scale="1" />
                                                </b-button>
                                            </td>
                                            <td width="5%" class="text-center">
                                                <b-button variant="info" size="sm" v-b-tooltip title="Solicitar Cadastro de Cartão" @click="GetPrepadCardURL(item.id)" v-b-modal.prepaidcard>
                                                    <b-icon icon="credit-card" font-scale="1" />
                                                </b-button>
                                            </td>
                                            <td width="5%" class="text-center">
                                                <b-button variant="primary" size="sm"
                                                    @click="$router.push({ path: '/clientes/cadastro/' + item.id})">
                                                    <b-icon v-if="has_permission('customer-edit')" icon="pen" font-scale="1" />
                                                    <b-icon v-else icon="clipboard" font-scale="1" />
                                                </b-button>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td :colspan="table_cols.length" class="text-center py-3">Nenhum registro encontrado</td>
                                        </tr>
                                    </template>
                                </rich-table>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </panel>
        </div>
    </div>
</template>

<script>
import Panel from '@/components/Panel'
import RichTable from '@/components/RichTable';
import _clone from 'lodash/clone'
import _merge from 'lodash/merge'
import UtilBar from '@/components/UtilBar'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            vue_component: 'customer_list',
            show: true,
            customer_id: null,
            prepaidcard_url: null,
            table_numbers: {
                size: null,
                total: null,
                found: null,
                footer: null
            },
            loadingData: false,
            filters: {
                professionals_type: 1,
                show: 2,
                name: '',
                inactive: null
            },
            items: []
        }
    },
    components: {
        Panel, RichTable, UtilBar
    },
    computed: {

        ...mapGetters('auth', [
            'has_permission'
        ]),

        table_cols() {
            let cols = [{
                    id: 'name',
                    text: 'Nome',
                    class: 'cols_name'
                },
                {
                    id: 'email',
                    text: 'E-mail',
                    class: 'cols_mail'
                },
                {
                    id: 'national_document',
                    text: 'CPF',
                    class: 'cols_national_document'
                },
                {
                    id: null,
                    text: 'Telefones',
                    class: 'cols_phone'
                },
                {
                    id: null,
                    text: '',
                    class: 'cols_button_4'
                },
                {
                    id: null,
                    text: '',
                    class: 'cols_button_1'
                },{
                    id: null,
                    text: '',
                    class: 'cols_button_2'
                },{
                    id: null,
                    text: '',
                    class: 'cols_button_3'
                },{
                    id: null,
                    text: '',
                    class: 'cols_button_3'
                }
            ];
            return cols;
        },
        
    },

    mounted() {
        this.LoadData();
    },

    methods: {
        async GetPrepadCardURL(id) {
            try {
                this.prepaidcard_url = null;
                var response = await this.$store.dispatch('customer/requestPrepaidCard', id);
                this.prepaidcard_url = response.url;
            }
            catch(e) {
                console.log(e);
            }
        },
        async CopyPrepadCardURL() {
            try {
                navigator.clipboard.writeText(this.prepaidcard_url);
            }
            catch(e) {
                console.log(e);
            }
        },
        LoadData() {
            this.loadingData = true;

            var params = _clone(_merge(this.filters, this.table_config));
            this.$store.dispatch('customer/getCustomers', params).then(data => {
                this.loadingData = false;

                this.items = data.items ? data.items : [];

                this.table_numbers.size = data.size ? data.size : 0;
                this.table_numbers.found = data.found ? data.found : 0;
                this.table_numbers.total = data.total ? data.total : 0;
                this.table_numbers.footer = data.footer ? data.footer : 0;

            });

        },

        InactiveFilter() {
            clearTimeout(this.handler);
            this.handler = setTimeout(() => {
                this.LoadData();
            }, 500);
        },

        CleanFilters() {
            this.$set(this, "filters", {
                professionals_type: 1,
                show: 2,
                name: '',
                inactive: null
            });

            this.$refs.customersRichTable.CleanFilters();
        },

        ChangeTableConfig(value) {
            this.table_config = value
            this.LoadData();
        },

        changePassword(customer){
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `Criação de Senha`,
                html: `Deseja realmente enviar um e-mail para o(a) cliente <strong>${customer.name}</strong> criar ou alterar sua senha de acesso ao App?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal({
                        showConfirmButton: false,
                        timer: 10000,
                        timerProgressBar: true,
                        title: 'Processando...',
                        icon: 'info',
                        padding: 20,
                    })

                    this.$store.dispatch('customer/changePasswordCustomerById', customer.id).then(response => {
                        this.$swal({
                            showConfirmButton: true,
                            timer: 3000,
                            timerProgressBar: true,
                            title: 'Atenção!',
                            text: response.success,
                            icon: 'success',
                            padding: 20,
                        })
                    }).catch(error => {
                        this.$swal({
                            showConfirmButton: true,
                            timer: 3000,
                            timerProgressBar: true,
                            title: 'Atenção!',
                            text: error.response.data.error,
                            icon: 'error',
                            padding: 20,
                        })
                    });
                }
            })
        },

        blockedCustomer(customer){
            this.$swal({
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                title: `${customer.blocked == 0 ? 'Bloquear' : 'Desbloquear'} Cliente`,
                html: `Deseja realmente ${customer.blocked == 0 ? 'BLOQUEAR' : 'DESBLOQUEAR'} o(a) cliente <strong>${customer.name}</strong>?`,
                icon: 'warning',
                padding: 20,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal({
                        showConfirmButton: false,
                        timer: 10000,
                        timerProgressBar: true,
                        title: 'Processando...',
                        icon: 'info',
                        padding: 20,
                    })

                    this.$store.dispatch('customer/blockedCustomerById', customer.id).then(response => {
                        this.$swal({
                            showConfirmButton: true,
                            timer: 3000,
                            timerProgressBar: true,
                            title: 'Atenção!',
                            text: response.success,
                            icon: 'success',
                            padding: 20,
                        })

                    }).catch(error => {
                        this.$swal({
                            showConfirmButton: true,
                            timer: 3000,
                            timerProgressBar: true,
                            title: 'Atenção!',
                            text: error.response.data.error,
                            icon: 'error',
                            padding: 20,
                        })
                    });
                }
            })
        },
    },

}
</script>

<style>

</style>