<template>
<div>
    <panel :loading="loading" title="HABILIDADES" icon="address-card" icon-lib="far" >
        <b-row>
            <b-col sm="12" md="12" lg="12" class="form-group">
                
                <template v-if="! OpenForm.habilities">
                    <p v-if="worker_habilities.length === 0">
                        <span>-</span>
                    </p>
                    <b-row v-else class="line-hability">
                        <b-col v-for="(hability, index) in worker_habilities_categories" :key="index" sm="12" md="12" lg="12">
                                <div class="mb-2 bg-mary-beleza-light">
                                    <h5 class="p-2">
                                        {{ habilityName(hability.hability_id) }}
                                    </h5>
                                </div>
                                
                                <b-row>
                                    <b-col v-for="(subHability, index) in worker_habilities(hability.hability_id)" :key="index" sm="12" md="12" lg="4">
                                        <b-card class="mb-3" >
                                            <div class="mb-2 text-mary-beleza justify-content-between d-flex">
                                                <h5>
                                                    {{ habilityName(subHability.hability_id) }}
                                                </h5>
                                                <help-icon v-if="subHability.obs" icon="exclamation-circle-fill" title="Observação" :name="'obs-'+hability.hability_id+'-'+index" colorClass="text-warning" scale="1.5">
                                                    {{ subHability.obs }}
                                                </help-icon>
                                            </div>
                                            <b-card-text align-h="between"> 
                                                <b-row class="ml-1">
                                                    <b-col sm="12" md="12" lg="12">
                                                        <b-row v-b-tooltip title="Tempo de experência">
                                                            <b class="pr-1">Experiência: </b>
                                                            <span v-if="subHability.time_experience == null || subHability.time_experience == ''">-</span>
                                                            <div v-else>
                                                                {{  timeExperienceText(subHability.time_experience) }}
                                                            </div>
                                                        </b-row>
                                                    </b-col>
                                                </b-row>
                                                    
                                                <b-row class="ml-1">
                                                    <b-col sm="12" md="12" lg="12">
                                                        <div v-b-tooltip title="Nota de avaliação">
                                                        <b-row>
                                                            <b class="pr-1">Nota (Autoavaliação):</b>
                                                            <span v-if="subHability.self_evaluation_grade == null || subHability.self_evaluation_grade == ''">-</span>
                                                            <div v-else>
                                                            {{ subHability.self_evaluation_grade }}
                                                            </div>
                                                        </b-row>
                                                        </div>
                                                    </b-col>
                                                    <b-col sm="12" md="12" lg="12">
                                                        <div v-b-tooltip title="Nota MB">
                                                        <b-row>
                                                            <b class="pr-1">Nota (Mary Beleza):</b>
                                                            <span v-if="subHability.mb_grade == null || subHability.mb_grade == ''">-</span>
                                                            <div v-else>
                                                            {{ subHability.mb_grade }}
                                                            </div>
                                                        </b-row>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                                    
                                            </b-card-text>
                                        </b-card>
                                    </b-col>
                                </b-row>
                        </b-col>
                    </b-row>
                </template>
                <template v-else>
                    <b-row>
                        <b-col v-for="(hability, index) in habilities" :key="index" sm="12" md="12" lg="12">
                            <b-card class="m-1 bg-light">
                                <b-row>
                                    <b-col sm="12" md="12" lg="12" class="mt-1">
                                        <b-form-checkbox switch :checked="!!WorkerHability(hability.id)" @change="worker_hability_checked({hability_id: hability.id, parent_id: hability.parent_id})">
                                            {{ hability.name }}
                                        </b-form-checkbox>
                                    </b-col>
                                </b-row>
                                <b-row v-if="WorkerHability(hability.id)" class="mt-3">
                                    <b-col v-for="(parent, index) in hability.parent" :key="index" sm="6" md="6" lg="6">
                                        <b-card class="mt-1 mb-1">
                                            <b-row>
                                                <b-col sm="12" md="12" lg="12" class="m-1">
                                                    <b-form-checkbox switch :checked="!!WorkerHability(parent.id)" @change="worker_hability_checked({hability_id: parent.id, parent_id: parent.parent_id}), copy(parent)">
                                                        <span class="font-weight-normal">{{ parent.name }}</span>
                                                    </b-form-checkbox>
                                                </b-col>
                                                <b-col sm="12" md="12" lg="12" class="mt-2 form-group" v-if="WorkerHability(parent.id)">
                                                    <label>Tempo de experiência:</label>
                                                    <b-form-select :value="WorkerHability(parent.id).time_experience" @input="time_experience(parent.id, $event), copy(parent)" :options="experience_options">
                                                    </b-form-select>
                                                </b-col>
                                                <b-col v-if="WorkerHability(parent.id)">
                                                    <label>Nota (Autoavaliação):</label>
                                                    <the-mask :value="WorkerHability(parent.id).self_evaluation_grade" @input="self_evaluation_grade(parent.id, $event), copy(parent)" placeholder="De 1 a 5" class="form-control" mask="N" :tokens="hexTokens" />
                                                </b-col>
                                                <b-col sm="6" md="6" lg="6" v-if="WorkerHability(parent.id) && ![constants.STATUS_PRE_REGISTRATION, constants.STATUS_APPROVED, constants.STATUS_WAITING_CHECKING].includes(status)">
                                                    <label>Nota (Mary Beleza):</label>
                                                    <the-mask :value="WorkerHability(parent.id).mb_grade" @input="mb_grade(parent.id, $event), copy(parent)" placeholder="De 1 a 5" class="form-control" mask="N" :tokens="hexTokens" />
                                                </b-col>
                                                <b-col sm="12" md="12" lg="12" v-if="WorkerHability(parent.id) && ![constants.STATUS_PRE_REGISTRATION, constants.STATUS_APPROVED, constants.STATUS_WAITING_CHECKING].includes(status)" class="mt-3">
                                                    <label>Observação:</label>
                                                    <b-form-textarea rows="2" :value="WorkerHability(parent.id).obs" @input="obs_hability(parent.id, $event)"></b-form-textarea>
                                                </b-col>
                                            </b-row>
                                        </b-card>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                </template>
            </b-col>
        </b-row>
        <b-row class="form-group">
            <b-col sm="12" md="12" lg="12">
                <div role="group">
                    <label>Observações Gerais:</label>
                    <template v-if="! OpenForm.habilities">
                        <p v-if="!habilities_observation">
                            <span>-</span>
                        </p>
                        <p v-else>
                            <span v-html="habilities_observation"></span>
                        </p>
                    </template>
                    <template v-else>
                        <b-form-textarea v-model="habilities_observation" rows="3"></b-form-textarea>
                        <b-form-invalid-feedback :state="!errors.habilities_observation">
                            <div v-for="error in errors.habilities_observation" :key="error">
                                {{ error }}
                            </div>
                        </b-form-invalid-feedback>
                    </template>
                </div>
            </b-col>
        </b-row>

        <b-row v-if="IsUpdate && has_permission('worker-edit')">
            <b-col sm="12" md="12" lg="12" class="form-group" v-if="!IsStage">
                <b-button v-if="!OpenForm.habilities" variant="mary-beleza" size="sm" @click="$store.dispatch('worker/OpenForm', 'habilities')">
                    <fa icon="pen" class="mr-2" /> Editar
                </b-button>
                <template v-else>
                    <b-button variant="outline-mary-beleza" size="sm" @click="$store.dispatch('worker/CancelEdit')" class="mr-2">
                        <fa icon="ban" class="mr-2" /> Cancelar
                    </b-button>
                    <b-button :disabled="loading" variant="mary-beleza" size="sm" @click="update()">
                        <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                    </b-button>
                </template>
            </b-col>
        </b-row>
    </panel>

    <panel v-if="IsUpdate && IsStage && has_permission('worker-edit')">
        <b-row>
            <b-col sm="12" md="12" lg="12">
                <b-button variant="outline-mary-beleza" size="sm" class="mr-2" @click="previous">
                    <b-icon icon="arrow-left-circle" class="mr-2" /> Anterior
                </b-button>
                <b-button variant="mary-beleza" size="sm" @click="update">
                    <b-icon icon="arrow-right-circle" class="mr-2" /> Próximo
                </b-button>
            </b-col>
        </b-row>
    </panel>
</div>
</template>

<script>
import Panel from '@/components/Panel'
import { mapGetters } from 'vuex'
import Constants from '@/constants'
import HelpIcon from '@/components/HelpIcon'

export default {

    components: {
        Panel,
        HelpIcon,
    },

    data() {
        return {
            loading: false,
            hexTokens: {
                N: {
                    pattern: /[1-5]/
                }
            },

            experience_options: [
                { value: null, text: 'Selecione...' },
                { value: 1, text: '0 a 3 meses' },
                { value: 2, text: '3 a 6 meses' },
                { value: 3, text: '6 meses a 1 ano' },
                { value: 4, text: '1 a 2 anos' },
                { value: 5, text: '2 a 5 anos' },
                { value: 6, text: 'Mais de 5 anos' },
            ]
        }
    },
    
    methods: {

        previous() {
            this.$store.commit('worker/STAGE_PREVIOUS')
        },
        
        self_evaluation_grade(id, grade){
            let data = { id, grade } 
            this.$store.commit('worker/SET_SELF_EVALUATION_GRADE', data)
        },

        mb_grade(id, grade){
            let data = { id, grade } 
            this.$store.commit('worker/SET_MB_GRADE', data)
        },

        time_experience(id, time_experience){
            let data = { id, time_experience } 
            this.$store.commit('worker/SET_TIME_EXPERIENCE', data)
        },

        obs_hability(id, obs){
            let data = { id, obs } 
            this.$store.commit('worker/SET_OBS_HABILITY', data)
        },

        worker_hability_checked(value){
            this.$store.commit('worker/SET_WORKER_HABILITIES', value)
        },

        update() {
            this.loading = true;
            this.$store.dispatch('worker/updateWorker').then(response => {
                if(this.IsStage){this.$store.commit('worker/STAGE_NEXT')}
                this.sweealert2('success', response.success)
                this.loading = false;
            }).catch(errors => {
                for(var item in errors){
                    this.sweealert2('error', errors[item])
                }
                this.loading = false;
            });
        },

        habilityName(id) {
            let hability = this.habilities_all.find(hability => hability.id == id)
            return hability?.name
        },

        timeExperienceText(value) {
            let experience = this.experience_options.find(experience => experience.value == value)
            return experience?.text
        },

        worker_habilities(parent_id){
            var filter_habilities = this.$store.state.worker.worker.worker_habilities
            return filter_habilities.filter(item => item.parent_id && item.parent_id == parent_id)
        },

        sweealert2(icon, text) {
            this.$swal({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                html: '&nbsp;&nbsp;'+text,
                icon: icon,
                padding: 20,
                background: '#dbf6e9'
            })
        },

        copy(hability) {
            if (!hability || !hability.copy_id) {
                return;
            }

            for(var item of this.habilities) {
                var exists = false;
                for(var subitem of item.parent) {
                    if (subitem.id == hability.copy_id) {
                        this.worker_hability_checked({hability_id: item.id, parent_id: item.parent_id, only_check: true});

                        var actived = !!this.WorkerHability(hability.id);
                        var actived2 = !!this.WorkerHability(subitem.id);

                        if (actived && !actived2) {
                            this.worker_hability_checked({hability_id: subitem.id, parent_id: item.id});
                        }
                        else if (!actived && actived2) {
                            this.worker_hability_checked({hability_id: subitem.id, parent_id: item.id});
                        }

                        var h = this.WorkerHability(hability.id);

                        this.time_experience(subitem.id, h.time_experience);
                        this.self_evaluation_grade(subitem.id, h.self_evaluation_grade);
                        this.mb_grade(subitem.id, h.mb_grade);
                        break;
                    }
                }

                if (exists) {
                    break;
                }
            }
        }
    },

    computed: {
        ...mapGetters('worker', [
            'OpenForm', 'IsUpdate', 'IsStage', 'WorkerHability'
        ]),

        ...mapGetters('auth', [
            'has_permission'
        ]),

        constants(){
            return Constants
        },

        habilities: {
            get() {
                return this.$store.state.worker.habilities;
            },
        },

        habilities_all: {
            get() {
                return this.$store.state.worker.habilities_all;
            },
        },

        status: {
            get() {
                return this.$store.state.worker.worker.status
            },
        },

        worker_habilities_categories: {
            get() {
                var filter_habilities = this.$store.state.worker.worker.worker_habilities
                return filter_habilities.filter(item => !item.parent_id)
            },
        },

        habilities_observation: {
            get() {
                return this.$store.state.worker.worker.detail.habilities_observation
            },
            set(value) {
                this.$store.commit('worker/SET_HABILITIES_OBSERVATION', value)
            }
        },

        errors: {
            get() {
                return this.$store.state.worker.errors;
            },
        },

    },

}
</script>

<style lang="scss">
.effect {
    width: 1.8rem !important;
    height: 1.8rem !important;
    right: 0 !important;
}

.vue-reaction {
    width: 1.8rem;
    height: 1.8rem;
    z-index: 0;

    img {
        width: 1.8rem;
        height: 1.8rem;

        &:hover {
            width: 2.3rem;
            height: 2.3rem;
        }
    }

    .line-hability {
        display: inline-block;
    }
}
</style>