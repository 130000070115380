<template>
<div>
    <panel :loading="loading" title="EXPERIÊNCIAS" icon="address-card" icon-lib="far">
        <b-tabs card active-nav-item-class="text-uppercase font-weight-bold">
            <!-- Render Tabs, supply a unique `key` to each tab -->
            <b-tab v-for="(experience, index) in experiences" active :key="'dyn-tab-' + index">
                <template #title>
                    <span>Experiência {{ index + 1 }}</span> <span class="text-uppercase"></span>
                    <span class="text-uppercase"></span>
                    <fa icon="times-circle" @click="RemoveExperience(index)" v-if="!new_experience && edit_index === null && has_permission('worker-edit')" />
                </template>

                <b-overlay rounded="sm">
                    <b-row class="form-group">
                        <b-col md="12">
                            <b-row class="form-group">
                                <b-col sm="12" md="12" lg="4">
                                    <div role="group">
                                        <label>Empresa:</label>
                                        <template v-if="edit_index != index && IsUpdate">
                                            <p v-if="!experience.place">
                                                -
                                            </p>
                                            <p v-else>{{experience.place}}</p>
                                        </template>
                                        <template v-else>
                                            <b-form-input v-model="experience.place" @input="UpdatePlace($event, index)" aria-describedby="input-live-help input-live-feedback" placeholder="Residência / Empresa"></b-form-input>
                                            <b-form-invalid-feedback :state="!errors[index+'.place']">
                                                <div v-for="error in errors[index+'.place']" :key="error">
                                                    {{ error }}
                                                </div>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </div>
                                </b-col>

                                <b-col sm="12" md="12" lg="4">
                                    <div role="group">
                                        <label>Cargo:</label>
                                        <template v-if="edit_index != index && IsUpdate">
                                            <p v-if="!experience.office">
                                                -
                                            </p>
                                            <p v-else>{{experience.office}}</p>
                                        </template>
                                        <template v-else>
                                            <b-form-input v-model="experience.office" @input="UpdateOffice($event, index)" aria-describedby="input-live-help input-live-feedback" placeholder="Cargo"></b-form-input>
                                            <b-form-invalid-feedback :state="!errors[index+'.office']">
                                                <div v-for="error in errors[index+'.office']" :key="error">
                                                    {{ error }}
                                                </div>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </div>
                                </b-col>

                                <b-col sm="12" md="12" lg="4">
                                    <div role="group">
                                        <label>Admissão:</label>
                                        <template v-if="edit_index != index && IsUpdate">
                                            <p v-if="!experience.admission">
                                                -
                                            </p>
                                            <p v-else>{{experience.admission | moment("DD/MM/YYYY") }}</p>
                                        </template>
                                        <template v-else>
                                            <typeable-date placeholder="dd/mm/aaaa" v-model="experience.admission"/>
                                            <b-form-invalid-feedback :state="!errors[index+'.admission']">
                                                <div v-for="error in errors[index+'.admission']" :key="error">
                                                    {{ error }}
                                                </div>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row class="form-group">
                                <b-col sm="12" md="6" lg="6">
                                    <div role="group">
                                        <label>Demissão:</label>
                                        <template v-if="edit_index != index && IsUpdate">
                                            <p v-if="!experience.dismissal">
                                                -
                                            </p>
                                            <p v-else>{{experience.dismissal | moment("DD/MM/YYYY") }}</p>
                                        </template>
                                        <template v-else>
                                            <typeable-date placeholder="dd/mm/aaaa" v-model="experience.dismissal"/>
                                            <b-form-invalid-feedback :state="!errors[index+'.dismissal']">
                                                <div v-for="error in errors[index+'.dismissal']" :key="error">
                                                    {{ error }}
                                                </div>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </div>
                                </b-col>
                                <b-col sm="12" md="6" lg="6">
                                    <div role="group">
                                        <label>Pessoa de contato:</label>
                                        <template v-if="edit_index != index && IsUpdate">
                                            <p v-if="!experience.contact">
                                                -
                                            </p>
                                            <p v-else>{{experience.contact}}</p>
                                        </template>
                                        <template v-else>
                                            <b-form-input v-model="experience.contact" @input="UpdateContact($event, index)" aria-describedby="input-live-help input-live-feedback" placeholder="Pessoa de contato"></b-form-input>
                                            <b-form-invalid-feedback :state="!errors[index+'.contact']">
                                                <div v-for="error in errors[index+'.contact']" :key="error">
                                                    {{ error }}
                                                </div>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </div>
                                </b-col>
                            </b-row>
                            
                            <b-row class="form-group">
                                <b-col sm="12" md="6" lg="6">
                                    <div role="group">
                                        <label>Telefone</label>
                                        <template v-if="edit_index != index && IsUpdate">
                                            <p v-if="!experience.phone">
                                                -
                                            </p>
                                            <p v-else>{{experience.phone | formatPhones }}</p>
                                        </template>
                                        <template v-else>
                                            <the-mask :mask="['(##) #####-####', '(##) ####-####']" class="form-control" v-model="experience.phone" @input="UpdatePhone($event, index)" placeholder="Informe um Telefone" />
                                            <b-form-invalid-feedback :state="!errors[index+'.phone']">
                                                <div v-for="error in errors[index+'.phone']" :key="error">
                                                    {{ error }}
                                                </div>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </div>
                                </b-col>
                                <b-col sm="12" md="6" lg="6">
                                    <div role="group">
                                        <label>Cidade/Bairro:</label>
                                        <template v-if="edit_index != index && IsUpdate">
                                            <p v-if="!experience.address">
                                                -
                                            </p>
                                            <p v-else>{{experience.address}}</p>
                                        </template>
                                        <template v-else>
                                            <b-form-input v-model="experience.address" @input="UpdateAddress($event, index)" aria-describedby="input-live-help input-live-feedback" placeholder="Cidade"></b-form-input>
                                            <b-form-invalid-feedback :state="!errors[index+'.address']">
                                                <div v-for="error in errors[index+'.address']" :key="error">
                                                    {{ error }}
                                                </div>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row class="form-group">
                                <b-col sm="12" md="12" lg="6">
                                    <div role="group">
                                        <label>Motivo Demissão pela versão do Profissional:</label>
                                        <template v-if="edit_index != index && IsUpdate">
                                            <p v-if="!experience.professional_allegations">
                                                -
                                            </p>
                                            <p v-else>
                                                <span v-html="experience.professional_allegations"></span>
                                            </p>
                                        </template>
                                        <template v-else>
                                            <b-form-textarea v-model="experience.professional_allegations" @input="UpdateProfessional_Allegations($event, index)" rows="4"></b-form-textarea>
                                            <b-form-invalid-feedback :state="!errors[index+'.professional_allegations']">
                                                <div v-for="error in errors[index+'.professional_allegations']" :key="error">
                                                    {{ error }}
                                                </div>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </div>
                                </b-col>
                                <b-col sm="12" md="12" lg="6">
                                    <div role="group">
                                        <label>Checagem:</label>
                                        <template v-if="edit_index != index && IsUpdate">
                                            <p v-if="!experience.checking">
                                                -
                                            </p>
                                            <p v-else>
                                                <span v-html="experience.checking"></span>
                                            </p>
                                        </template>
                                        <template v-else>
                                            <b-form-textarea v-model="experience.checking" @input="UpdateChecking($event, index)" rows="4"></b-form-textarea>
                                            <b-form-invalid-feedback :state="!errors[index+'.checking']">
                                                <div v-for="error in errors[index+'.checking']" :key="error">
                                                    {{ error }}
                                                </div>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row v-if="IsUpdate && has_permission('worker-edit')">
                                <b-col sm="12">
                                    <template>
                                            <b-button v-if="edit_index === index" variant="outline-mary-beleza" size="sm" @click="new_experience ? RemoveExperience(index) : CancelExperience(index)" class="mr-2">
                                                <fa icon="ban" class="mr-2" /> Cancelar
                                            </b-button>
                                            <b-button v-if="edit_index != index" variant="mary-beleza" class="mr-2" size="sm" @click="EditExperience(index)" :disabled="new_experience || edit_index != null">
                                                <fa icon="pen" class="mr-2" /> Editar
                                            </b-button>
                                        <b-button :disabled="loading" v-if="edit_index === index" variant="mary-beleza" size="sm" @click="new_experience ? SaveExperience(index) : UpdateExperience(index)">
                                            <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                                        </b-button>
                                    </template>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-overlay>
            </b-tab>
            <!-- New Tab Button (Using tabs-end slot) -->
            <template #tabs-end v-if="!new_experience && edit_index === null && has_permission('worker-edit')">
                <b-nav-item role="presentation" @click.prevent="AddExperience" href="#">
                    <fa icon="plus-circle" /> Adicionar
                </b-nav-item>
            </template>
        </b-tabs>
    </panel>

    <panel v-if="IsStage">
        <b-col sm="12">
            <b-button variant="outline-mary-beleza" size="sm" class="mr-2" @click="previous">
                <b-icon icon="arrow-left-circle" class="mr-2" /> Anterior
            </b-button>
            <b-button variant="mary-beleza" size="sm" @click="next">
                <b-icon icon="arrow-right-circle" class="mr-2" /> Próximo
            </b-button>
        </b-col>
    </panel>
</div>
</template>

<script>
import Panel from '@/components/Panel';
import TypeableDate from '@/components/TypeableDate'
import { mapGetters } from 'vuex'

export default {
    components: {
        Panel,
        TypeableDate
    },

    data() {
        return {
            loading: false,
            edit_index: null, // Referencia o INDEX que está em edição, para liberar os inputs.
            new_experience: false, // Indica se está sendo criado um novo, se sim ele desabilita alguns botões.
            backup_experience: null
        }
    },

    methods: {

        next() {
            this.$store.commit('worker/STAGE_NEXT') 
        },

        previous() {
            this.$store.commit('worker/STAGE_PREVIOUS')
        },

        AddExperience() {
            this.$store.commit("worker/SET_EXPERIENCE_ADD");

            /* IF para ver se é edição
            Se sim ele adiciona o index do novo produto no 'edit_index'
            e deixa o 'new_experience' como verdadeiro */
            if (this.IsUpdate) {
                this.edit_index = this.experiences.length - 1
                this.new_experience = true;
            }
        },
        RemoveExperience(index) {

            /* IF para ver se é edição
            Se sim ele deixa o 'new_experience' como falso 
            e adiciona o valor null para o 'edit_index' */
            if (this.IsUpdate && !this.new_experience) {
                this.$swal({
                    title: 'Tem certeza que deseja excluir?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Sim',
                    denyButtonText: 'Não',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('worker/deleteExperience', index).then(response => {
                            this.sweealert2('success', response.success)
                        }).catch(error => {
                            this.sweealert2('error', error.response.data)
                        });
                    }
                })
            } else {
                this.$store.commit("worker/SET_DELETE_EXPERIENCE", index);
            }

            this.new_experience = false
            this.edit_index = null
        },

        SaveExperience(index) {
            this.loading = true;
            this.$store.dispatch('worker/createExperience', index).then(response => {

                this.new_experience = false
                this.edit_index = null

                this.sweealert2('success', response.success)
                this.loading = false;

            }).catch(errors => {
                for(var item in errors){
                    this.sweealert2('error', errors[item])
                    this.loading = false;
                }
            });
        },

        UpdateExperience(index) {
            this.loading = true;
            this.$store.dispatch('worker/updateExperience', index).then(response => {

                this.edit_index = null

                this.sweealert2('success', response.success)
                this.loading = false;

            }).catch(errors => {
                for(var item in errors){
                    this.sweealert2('error', errors[item])
                    this.loading = false;
                }
            });
        },

        EditExperience(index) {
            this.edit_index = index
            this.$store.commit("worker/SET_EDIT_EXPERIENCE", index);
        },

        CancelExperience(index) {
            this.edit_index = null
            this.$store.commit("worker/CANCEL_EDIT_EXPERIENCE", index);
        },

        UpdatePlace(value, index) {
            var data = {
                value,
                index
            };
            this.$store.commit("worker/SET_PLACE", data);
        },
        UpdateOffice(value, index) {
            var data = {
                value,
                index
            };
            this.$store.commit("worker/SET_OFFICE", data);
        },
        UpdateAdmission(value, index) {
            var data = {
                value,
                index
            };
            this.$store.commit("worker/SET_ADMISSION", data);
        },
        UpdateDismissal(value, index) {
            var data = {
                value,
                index
            };
            this.$store.commit("worker/SET_DISMISSAL", data);
        },
        UpdateContact(value, index) {
            var data = {
                value,
                index
            };
            this.$store.commit("worker/SET_CONTACT", data);
        },
        UpdatePhone(value, index) {
            var data = {
                value,
                index
            };
            this.$store.commit("worker/SET_PHONE", data);
        },
        UpdateProfessional_Allegations(value, index) {
            var data = {
                value,
                index
            };
            this.$store.commit("worker/SET_PROFESSIONAL_ALLEGATIONS", data);
        },
        UpdateChecking(value, index) {
            var data = {
                value,
                index
            };
            this.$store.commit("worker/SET_CHECKING", data);
        },
        UpdateAddress(value, index) {
            var data = {
                value,
                index
            };
            this.$store.commit("worker/SET_ADDRESS", data);
        },

        sweealert2(icon, text) {
            var bgColor = '#dbf6e9';

            if(icon == 'error')
                bgColor = '#f4c8c8'

            this.$swal({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                html: '&nbsp;&nbsp;'+text,
                icon: icon,
                padding: 20,
                background: bgColor
            })
        },
    },

    computed: {
        ...mapGetters('worker', [
            'OpenForm', 'IsUpdate', 'IsStage'
        ]),

        ...mapGetters('auth', [ 'has_permission' ]),

        experiences: {
            get() {
                return this.$store.state.worker.worker.experiences;
            },
        },

        worker_status: {
            get() {
                return this.$store.state.worker.worker.status;
            },
        },

        errors: {
            get() {
                return this.$store.state.worker.errors;
            },
        },

    },
};
</script>
