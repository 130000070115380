<template>
<div>
    <panel :loading="loading" title="INFORMAÇÕES PESSOAIS COMPLEMENTARES" icon="address-card" icon-lib="far">
        <b-row>
            <b-col md="12">
                <b-row class="form-group">
                    <b-col>
                        <div role="group">
                            <label>Como ficou sabendo da existência da Mary Beleza:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p v-if="!existence">
                                    <b class="text-danger">-</b>
                                </p>
                                <p v-else-if="existence == 15">{{ who_existence }}</p>
                                <p v-else >{{ existence_name(existence) }}</p>
                            </template>
                            <template v-else>
                                <b-form-select v-model="existence" :options="existence_options"></b-form-select>
                                <b-form-invalid-feedback :state="!errors.existence">
                                    <div v-for="error in errors.existence" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>  
                    <b-col sm="4" v-if="existence == 15">
                        <div role="group">
                            <label>Qual outro:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p v-if="!who_existence">
                                    <b class="text-danger">-</b>
                                </p>
                                <p v-else>{{ who_existence }}</p>
                            </template>
                            <template v-else>
                                <b-form-input v-model="who_existence"  placeholder="Qual outro"></b-form-input>
                                <b-form-invalid-feedback :state="!errors.who_existence">
                                    <div v-for="error in errors.who_existence" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>  
                </b-row>

                <b-row class="form-group">
                    <b-col sm="12" md="12" lg="5">
                        <div role="group">
                            <label>Estaria disposto a fazer atendimentos em domicílio?</label>
                            <template v-if="! OpenForm.additional_information">
                                <p>
                                    <b v-if="accept_home_care == null">-</b>
                                    <b-badge v-else-if="accept_home_care" variant="success">Sim</b-badge>
                                    <b-badge v-else variant="danger">Não</b-badge>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-select v-model="accept_home_care" :options="options"></b-form-select>
                                <b-form-invalid-feedback :state="!errors.accept_home_care">
                                    <div v-for="error in errors.accept_home_care" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                    <b-col sm="12" md="12" lg="5">
                        <div role="group">
                            <label>Você já trabalha com algum aplicativo de beleza?</label>
                            <template v-if="! OpenForm.additional_information">
                                <p>
                                    <b v-if="another_beauty_app == null">-</b>
                                    <b-badge v-else-if="another_beauty_app" variant="success">Sim</b-badge>
                                    <b-badge v-else variant="danger">Não</b-badge>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-select v-model="another_beauty_app" :options="options"></b-form-select>
                                <b-form-invalid-feedback :state="!errors.another_beauty_app">
                                    <div v-for="error in errors.another_beauty_app" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                    <b-col sm="12" md="12" lg="2">
                        <div role="group">
                            <label>Tem MEI:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p>
                                    <b v-if="has_mei == null" >-</b>
                                    <b-badge v-else-if="has_mei" variant="success">Sim</b-badge>
                                    <b-badge v-else variant="danger">Não</b-badge>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-select v-model="has_mei" :options="options"></b-form-select>
                                <b-form-invalid-feedback :state="!errors.has_mei">
                                    <div v-for="error in errors.has_mei" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="form-group">
                    <b-col sm="12" md="12" lg="12">
                        <div role="group">
                            <label>Tem veiculo próprio:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p>
                                    <b v-if="has_vehicle == null" >-</b>
                                    <b-badge v-else-if="has_vehicle" variant="success">Sim</b-badge>
                                    <b-badge v-else variant="danger">Não</b-badge>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-select v-model="has_vehicle" :options="options"></b-form-select>
                                <b-form-invalid-feedback :state="!errors.has_vehicle">
                                    <div v-for="error in errors.has_vehicle" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="form-group" v-if="has_vehicle">
                    <b-col sm="12" md="12" lg="12">
                        <div role="group">
                            <label>Descreva seu veiculo:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p v-if="!has_vehicle_which">
                                    <b>-</b>
                                </p>
                                <p v-else>
                                    <span v-html="has_vehicle_which"></span>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-textarea v-model="has_vehicle_which" placeholder="..." rows="4"></b-form-textarea>
                                <b-form-invalid-feedback :state="!errors.has_vehicle_which">
                                    <div v-for="error in errors.has_vehicle_which" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="form-group">
                    <b-col sm="12" md="12" lg="12">
                        <div role="group">
                            <label>Referências profissionais:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p v-if="!professional_references">
                                    -
                                </p>
                                <p v-else><span v-html="professional_references"></span></p>
                            </template>
                            <template v-else>
                                <b-form-textarea v-model="professional_references" placeholder="..." rows="4"></b-form-textarea>
                                <b-form-invalid-feedback :state="!errors.professional_references">
                                    <div v-for="error in errors.professional_references" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="form-group">
                    <b-col>
                        <div role="group">
                            <label>Tem filhos:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p>
                                    <b v-if="children == null">-</b>
                                    <b-badge v-else-if="children" variant="success">Sim</b-badge>
                                    <b-badge v-else variant="danger">Não</b-badge>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-select v-model="children" :options="options"></b-form-select>
                                <b-form-invalid-feedback :state="!errors.children">
                                    <div v-for="error in errors.children" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>

                    <b-col v-if="children">
                        <div role="group">
                            <label>Quantos:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p v-if="!children_quantity">
                                    -
                                </p>
                                <p v-else>{{children_quantity}}</p>
                            </template>
                            <template v-else>
                                <b-form-input v-model="children_quantity" type="number" aria-describedby="input-live-help input-live-feedback"></b-form-input>
                                <b-form-invalid-feedback :state="!errors.children_quantity">
                                    <div v-for="error in errors.children_quantity" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>

                    <b-col v-if="children">
                        <div role="group">
                            <label>Menor de 10 anos:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p>
                                    <b v-if="children_minor == null">-</b>
                                    <b-badge v-else-if="children_minor" variant="success">Sim</b-badge>
                                    <b-badge v-else variant="danger">Não</b-badge>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-select v-model="children_minor" :options="options"></b-form-select>
                                <b-form-invalid-feedback :state="!errors.children_minor">
                                    <div v-for="error in errors.children_minor" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="form-group">
                    <b-col v-if="children_minor">
                        <div role="group">
                            <label>Quantos:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p v-if="!children_minor_quantity">
                                    -
                                </p>
                                <p v-else>{{children_minor_quantity}}</p>
                            </template>
                            <template v-else>
                                <b-form-input v-model="children_minor_quantity" type="number" aria-describedby="input-live-help input-live-feedback"></b-form-input>
                                <b-form-invalid-feedback :state="!errors.children_minor_quantity">
                                    <div v-for="error in errors.children_minor_quantity" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="children_minor" class="form-group">
                    <b-col>
                        <div role="group">
                            <label>Com quem ficam seus filhos menores de idade durante o seu trabalho?</label>
                            <template v-if="! OpenForm.additional_information">
                                <p v-if="!children_who">
                                    <b>-</b>
                                </p>
                                <p v-else>
                                    <span v-html="children_who"></span>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-textarea v-model="children_who" placeholder="..." rows="6"></b-form-textarea>
                                <b-form-invalid-feedback :state="!errors.children_who">
                                    <div v-for="error in errors.children_who" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="form-group">

                    <b-col>
                        <div role="group">
                            <label>Fuma:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p>
                                    <span v-if="smoke == null">-</span>
                                    <b-badge v-else-if="smoke" variant="success">Sim</b-badge>
                                    <b-badge v-else variant="danger">Não</b-badge>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-select v-model="smoke" :options="options"></b-form-select>
                                <b-form-invalid-feedback :state="!errors.smoke">
                                    <div v-for="error in errors.smoke" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                    <b-col>
                        <div role="group">
                            <label>Bebe:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p>
                                    <span v-if="drink == null">-</span>
                                    <b-badge v-else-if="drink" variant="success">Sim</b-badge>
                                    <b-badge v-else variant="danger">Não</b-badge>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-select v-model="drink" :options="options"></b-form-select>
                                <b-form-invalid-feedback :state="!errors.drink">
                                    <div v-for="error in errors.drink" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="drink || smoke" class="form-group">
                    <b-col>
                        <div role="group">
                            <label>Detalhes:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p v-if="!smoke_drink_detail">
                                    -
                                </p>
                                <p v-else>
                                    <span v-html="smoke_drink_detail"></span>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-textarea v-model="smoke_drink_detail" placeholder="..." rows="6"></b-form-textarea>
                                <b-form-invalid-feedback :state="!errors.smoke_drink_detail">
                                    <div v-for="error in errors.smoke_drink_detail" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="form-group">
                    <b-col>
                        <div role="group">
                            <label>Drogas:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p>
                                    <b v-if="family_drugs == null">-</b>
                                    <b-badge v-else-if="family_drugs" variant="success">Sim</b-badge>
                                    <b-badge v-else variant="danger">Não</b-badge>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-select v-model="family_drugs" :options="options"></b-form-select>
                                <b-form-invalid-feedback :state="!errors.family_drugs">
                                    <div v-for="error in errors.family_drugs" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="family_drugs" class="form-group">
                    <b-col>
                        <div role="group">
                            <label>Detalhes:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p v-if="!family_drugs_detail">
                                    -
                                </p>
                                <p v-else>
                                    <span v-html="family_drugs_detail"></span>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-textarea v-model="family_drugs_detail" placeholder="..." rows="6"></b-form-textarea>
                                <b-form-invalid-feedback :state="!errors.family_drugs_detail">
                                    <div v-for="error in errors.family_drugs_detail" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="form-group">
                    <b-col>
                        <div role="group">
                            <label>Antecedentes Criminais:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p>
                                    <span v-if="family_justice == null">-</span>
                                    <b-badge v-else-if="family_justice" variant="success">Sim</b-badge>
                                    <b-badge v-else variant="danger">Não</b-badge>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-select v-model="family_justice" :options="options"></b-form-select>
                                <b-form-invalid-feedback :state="!errors.family_justice">
                                    <div v-for="error in errors.family_justice" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="family_justice" class="form-group">
                    <b-col>
                        <div role="group">
                            <label>Detalhes:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p v-if="!family_justice_detail">
                                    -
                                </p>
                                <p v-else>
                                    <span v-html="family_justice_detail"></span>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-textarea v-model="family_justice_detail" placeholder="..." rows="6"></b-form-textarea>
                                <b-form-invalid-feedback :state="!errors.family_justice_detail">
                                    <div v-for="error in errors.family_justice_detail" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="form-group">
                    <b-col>
                        <div role="group">
                            <label>Problemas de saúde:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p>
                                    <span v-if="worker_health == null">-</span>
                                    <b-badge v-else-if="worker_health" variant="success">Sim</b-badge>
                                    <b-badge v-else variant="danger">Não</b-badge>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-select v-model="worker_health" :options="options"></b-form-select>
                                <b-form-invalid-feedback :state="!errors.worker_health">
                                    <div v-for="error in errors.worker_health" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="worker_health" class="form-group">
                    <b-col>
                        <div role="group">
                            <label>Detalhes:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p v-if="!worker_health_detail">
                                    -
                                </p>
                                <p v-else>
                                    <span v-html="worker_health_detail"></span>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-textarea v-model="worker_health_detail" placeholder="..." rows="6"></b-form-textarea>
                                <b-form-invalid-feedback :state="!errors.worker_health_detail">
                                    <div v-for="error in errors.worker_health_detail" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="form-group">
                    <b-col>
                        <div role="group">
                            <label>Parentes com problema de saúde:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p>
                                    <span v-if="family_health == null">-</span>
                                    <b-badge v-else-if="family_health" variant="success">Sim</b-badge>
                                    <b-badge v-else variant="danger">Não</b-badge>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-select v-model="family_health" :options="options"></b-form-select>
                                <b-form-invalid-feedback :state="!errors.family_health">
                                    <div v-for="error in errors.family_health" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="family_health" class="form-group">
                    <b-col>
                        <div role="group">
                            <label>Detalhes:</label>
                            <template v-if="! OpenForm.additional_information">
                                <p v-if="!family_health_detail">
                                    -
                                </p>
                                <p v-else>
                                    <span v-html="family_health_detail"></span>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-textarea v-model="family_health_detail" placeholder="..." rows="6"></b-form-textarea>
                                <b-form-invalid-feedback :state="!errors.family_health_detail">
                                    <div v-for="error in errors.family_health_detail" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="IsUpdate && has_permission('worker-edit')">
                    <b-col sm="12" v-if="!IsStage">
                        <b-button v-if="!OpenForm.additional_information" variant="mary-beleza" size="sm" @click="$store.dispatch('worker/OpenForm', 'additional_information')">
                            <fa icon="pen" class="mr-2" /> Editar
                        </b-button>
                        <template v-else>
                            <b-button variant="outline-mary-beleza" size="sm" @click="$store.dispatch('worker/CancelEdit')" class="mr-2">
                                <fa icon="ban" class="mr-2" /> Cancelar
                            </b-button>
                            <b-button :disabled="loading" variant="mary-beleza" size="sm" @click="update()">
                                <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                            </b-button>
                        </template>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </panel>

    <panel v-if="IsUpdate && IsStage && has_permission('worker-edit')">
        <b-row>
            <b-col sm="12">
                <b-button variant="outline-mary-beleza" size="sm" class="mr-2" @click="previous">
                    <b-icon icon="arrow-left-circle" class="mr-2" /> Anterior
                </b-button>
                <b-button variant="mary-beleza" size="sm" @click="update">
                    <b-icon icon="arrow-right-circle" class="mr-2" /> Próximo
                </b-button>
            </b-col>
        </b-row>
    </panel>
</div>
</template>

<script>
import Panel from "@/components/Panel"
import {
    mapGetters
} from 'vuex'

export default {

    components: {
        Panel
    },

    data() {
        return {
            loading: false,
            options: [{
                    text: 'Sim',
                    value: 1
                },
                {
                    text: 'Não',
                    value: 0
                },
            ],
            existence_options: [
                { value: null, text: 'Selecione', disabled: true },
                { value: 1, text: 'Bloguerias' },
                { value: 2, text: 'Cartão de Visita' },
                { value: 3, text: 'E-mail Marketing' },
                { value: 4, text: 'Facebook' },
                { value: 5, text: 'Google' },
                { value: 6, text: 'Instagram' },
                { value: 7, text: 'Indicação de Amigos' },
                { value: 8, text: 'Jornal' },
                { value: 9, text: 'Outdoor' },
                { value: 10, text: 'Panfletos de Propaganda' },
                { value: 11, text: 'Revistas' },
                { value: 12, text: 'SMS' },
                { value: 13, text: 'WhatsApp' },
                { value: 14, text: '', disabled: true },
                { value: 15, text: 'Outros' }
            ],
        }
    },

    methods: {

        previous() {
            this.$store.commit('worker/STAGE_PREVIOUS')
        },

        update() {
            this.loading = true;
            this.$store.dispatch('worker/updateWorker').then(response => {
                if(this.IsStage){this.$store.commit('worker/STAGE_NEXT')}
                this.sweealert2('success', response.success)
                this.loading = false;
            }).catch(errors => {
                for(var item in errors){
                    this.sweealert2('error', errors[item])
                    this.loading = false;
                }
            });
        },

        sweealert2(icon, text) {
            this.$swal({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                html: '&nbsp;&nbsp;'+text,
                icon: icon,
                padding: 20,
                background: '#dbf6e9'
            })
        },

        existence_name(id){
            var existence = this.existence_options.find(item => item.value == id)
            return existence?.text
        },
    },

    computed: {

        ...mapGetters('worker', [
            'OpenForm', 'IsUpdate', 'IsStage'
        ]),

        ...mapGetters('auth', [
            'has_permission'
        ]),

        existence: {
            get() {
                return this.$store.state.worker.worker.person.existence;
            },
            set(value) { 
                this.$store.commit('worker/SET_EXISTENCE', value) 
            }
        },

        who_existence: {
            get() { 
                return this.$store.state.worker.worker.person.who_existence ;
            },
            set(value) { 
                this.$store.commit('worker/SET_WHO_EXISTENCE', value) 
            }
        },

        children: {
            get() {
                return this.$store.state.worker.worker.detail.children;
            },
            set(value) {
                this.$store.commit('worker/SET_CHILDREN', value)
            }
        },

        children_quantity: {
            get() {
                return this.$store.state.worker.worker.detail.children_quantity;
            },
            set(value) {
                this.$store.commit('worker/SET_CHILDREN_QUANTITY', value)
            }
        },

        children_minor: {
            get() {
                return this.$store.state.worker.worker.detail.children_minor;
            },
            set(value) {
                this.$store.commit('worker/SET_CHILDREN_MINOR', value)
            }
        },

        children_minor_quantity: {
            get() {
                return this.$store.state.worker.worker.detail.children_minor_quantity;
            },
            set(value) {
                this.$store.commit('worker/SET_CHILDREN_MINOR_QUANTITY', value)
            }
        },

        children_who: {
            get() {
                return this.$store.state.worker.worker.detail.children_who;
            },
            set(value) {
                this.$store.commit('worker/SET_CHILDREN_WHO', value)
            }
        },

        smoke: {
            get() {
                return this.$store.state.worker.worker.detail.smoke;
            },
            set(value) {
                this.$store.commit('worker/SET_SMOKE', value)
            }
        },

        drink: {
            get() {
                return this.$store.state.worker.worker.detail.drink;
            },
            set(value) {
                this.$store.commit('worker/SET_DRINK', value)
            }
        },

        smoke_drink_detail: {
            get() {
                return this.$store.state.worker.worker.detail.smoke_drink_detail;
            },
            set(value) {
                this.$store.commit('worker/SET_SMOKE_DRINK_DETAIL', value)
            }
        },

        family_drugs: {
            get() {
                return this.$store.state.worker.worker.detail.family_drugs;
            },
            set(value) {
                this.$store.commit('worker/SET_FAMILY_DRUGS', value)
            }
        },

        family_drugs_detail: {
            get() {
                return this.$store.state.worker.worker.detail.family_drugs_detail;
            },
            set(value) {
                this.$store.commit('worker/SET_FAMILY_DRUGS_DETAIL', value)
            }
        },

        family_justice: {
            get() {
                return this.$store.state.worker.worker.detail.family_justice;
            },
            set(value) {
                this.$store.commit('worker/SET_FAMILY_JUSTICE', value)
            }
        },

        family_justice_detail: {
            get() {
                return this.$store.state.worker.worker.detail.family_justice_detail;
            },
            set(value) {
                this.$store.commit('worker/SET_FAMILY_JUSTICE_DETAIL', value)
            }
        },

        worker_health: {
            get() {
                return this.$store.state.worker.worker.detail.worker_health;
            },
            set(value) {
                this.$store.commit('worker/SET_WORKER_HEALTH', value)
            }
        },

        worker_health_detail: {
            get() {
                return this.$store.state.worker.worker.detail.worker_health_detail;
            },
            set(value) {
                this.$store.commit('worker/SET_WORKER_HEALTH_DETAIL', value)
            }
        },

        family_health: {
            get() {
                return this.$store.state.worker.worker.detail.family_health;
            },
            set(value) {
                this.$store.commit('worker/SET_FAMILY_HEALTH', value)
            }
        },

        family_health_detail: {
            get() {
                return this.$store.state.worker.worker.detail.family_health_detail;
            },
            set(value) {
                this.$store.commit('worker/SET_FAMILY_HEALTH_DETAIL', value)
            }
        },

        accept_home_care: {
            get() {
                return this.$store.state.worker.worker.detail.accept_home_care;
            },
            set(value) {
                this.$store.commit('worker/SET_ACCEPT_HOME_CARE', value)
            }
        },

        has_mei: {
            get() {
                return this.$store.state.worker.worker.detail.has_mei;
            },
            set(value) {
                this.$store.commit('worker/SET_HAS_MEI', value)
            }
        },

        has_vehicle: {
            get() {
                return this.$store.state.worker.worker.detail.has_vehicle;
            },
            set(value) {
                this.$store.commit('worker/SET_HAS_VEHICLE', value)
            }
        },

        has_vehicle_which: {
            get() {
                return this.$store.state.worker.worker.detail.has_vehicle_which;
            },
            set(value) {
                this.$store.commit('worker/SET_HAS_VEHICLE_WHICH', value)
            }
        },

        another_beauty_app: {
            get() {
                return this.$store.state.worker.worker.detail.another_beauty_app;
            },
            set(value) {
                this.$store.commit('worker/SET_ANOTHER_BEAUTY_APP', value)
            }
        },

        professional_references: {
            get() {
                return this.$store.state.worker.worker.detail.professional_references;
            },
            set(value) {
                this.$store.commit('worker/SET_PROFESSIONAL_REFERENCES', value)
            }
        },

        errors: {
            get() {
                return this.$store.state.worker.errors;
            },
        },
    },
}
</script>
