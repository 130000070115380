<template>
<div>
    <panel :loading="loading" title="FORMAÇÃO ESCOLAR" icon="address-card" icon-lib="far">
        <b-row>
            <b-col md="12">
                <b-row class="form-group">
                    <b-col cols="6" sm="6" md="6" lg="2">
                        <div>
                            <label>Sabe ler:</label>
                            <template v-if="! OpenForm.schooling">
                                <p>
                                    <span v-if="read == null">-</span>
                                    <b-badge v-else-if="read" variant="success">Sim</b-badge>
                                    <b-badge v-else variant="danger">Não</b-badge>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-radio-group v-model="read" :options="options" button-variant="outline-primary"></b-form-radio-group>
                                <b-form-invalid-feedback :state="!errors.read">
                                    <div v-for="error in errors.read" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>

                    <b-col cols="6" sm="6" md="6" lg="2">
                        <div>
                            <label>Sabe escrever:</label>
                            <template v-if="! OpenForm.schooling">
                                <p>
                                    <span v-if="write == null">-</span>
                                    <b-badge v-else-if="write" variant="success">Sim</b-badge>
                                    <b-badge v-else variant="danger">Não</b-badge>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-radio-group v-model="write" :options="options" button-variant="outline-primary"></b-form-radio-group>
                                <b-form-invalid-feedback :state="!errors.write">
                                    <div v-for="error in errors.write" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>

                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label>Nivel de educação escolar:</label>
                            <template v-if="! OpenForm.schooling">
                                <p v-if="!schoolingName()">
                                    -
                                </p>
                                <p v-else>{{ schoolingName() }}</p>
                            </template>
                            <template v-else>
                                <b-form-select v-model="schooling" :options="schooling_options">
                                    <template #first>
                                        <b-form-select-option :value="null">Selecione...</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback :state="!errors.schooling">
                                    <div v-for="error in errors.schooling" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                    <b-col sm="12" md="12" lg="4">
                        <div role="group">
                            <label>Formado em:</label>
                            <template v-if="! OpenForm.schooling">
                                <p v-if="!graduated_at">
                                    -
                                </p>
                                <p v-else>{{ graduated_at | moment("DD/MM/YYYY") }}</p>
                            </template>
                            <template v-else>
                                <typeable-date placeholder="dd/mm/aaaa" v-model="graduated_at"/>
                                <b-form-invalid-feedback :state="!errors.graduated_at">
                                    <div v-for="error in errors.graduated_at" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="form-group">
                    <b-col>
                        <div role="group">
                            <label>Citar cursos:</label>
                            <template v-if="! OpenForm.schooling">
                                <p v-if="!extension_curses">
                                    -
                                </p>
                                <p v-else>
                                    <span v-html="extension_curses"></span>
                                </p>
                            </template>
                            <template v-else>
                                <b-form-textarea id="textarea-rows" v-model="extension_curses" placeholder="..." rows="4"></b-form-textarea>
                                <b-form-invalid-feedback :state="!errors.extension_curses">
                                    <div v-for="error in errors.extension_curses" :key="error">
                                        {{ error }}
                                    </div>
                                </b-form-invalid-feedback>
                            </template>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="IsUpdate && has_permission('worker-edit')">
                    <b-col sm="12" v-if="!IsStage">
                        <b-button v-if="!OpenForm.schooling" variant="mary-beleza" size="sm" @click="$store.dispatch('worker/OpenForm', 'schooling')">
                            <fa icon="pen" class="mr-2" /> Editar
                        </b-button>
                        <template v-else>
                            <b-button variant="outline-mary-beleza" size="sm" @click="$store.dispatch('worker/CancelEdit')" class="mr-2">
                                <fa icon="ban" class="mr-2" /> Cancelar
                            </b-button>
                            <b-button :disabled="loading" variant="mary-beleza" size="sm" @click="update()">
                                <fa :icon="['far', 'save']" class="mr-2" /> Salvar
                            </b-button>
                        </template>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </panel>
    
    <panel v-if="IsUpdate && IsStage && has_permission('worker-edit')">
        <b-row>
            <b-col sm="12" v-if="IsStage">
                <b-button variant="outline-mary-beleza" size="sm" class="mr-2" @click="previous">
                    <b-icon icon="arrow-left-circle" class="mr-2" /> Anterior
                </b-button>
                <b-button variant="mary-beleza" size="sm" @click="update">
                    <b-icon icon="arrow-right-circle" class="mr-2" /> Próximo
                </b-button>
            </b-col>
        </b-row>
    </panel>
</div>
</template>

<script>
import Panel from '@/components/Panel'
import TypeableDate from '@/components/TypeableDate'
import { mapGetters } from 'vuex';

export default {

    components: {
        Panel,
        TypeableDate
    },

    data() {
        return {
            loading: false,
            schooling_options: [
                {
                    text: 'Fundamental Incompleto',
                    value: 1
                },
                {
                    text: 'Fundamental Completo',
                    value: 1
                },
                {
                    text: 'Médio Incompleto',
                    value: 2
                },
                {
                    text: 'Médio Completo',
                    value: 3
                },
                {
                    text: 'Superior Incompleto',
                    value: 4
                },
                {
                    text: 'Superior Completo',
                    value: 5
                },
                {
                    text: 'Mestrado',
                    value: 5
                },
                {
                    text: 'Doutorado',
                    value: 5
                },
            ],
            options: [{
                    text: 'Sim',
                    value: 1
                },
                {
                    text: 'Não',
                    value: 0
                },
            ],
        }

    },

    methods: {

        previous() {
            this.$store.commit('worker/STAGE_PREVIOUS')
        },

        update() {
            this.loading = true;
            this.$store.dispatch('worker/updateWorker').then(response => {
                if(this.IsStage){this.$store.commit('worker/STAGE_NEXT')}
                this.sweealert2('success', response.success)
                this.loading = false;
            }).catch(errors => {
                for(var item in errors){
                    this.sweealert2('error', errors[item])
                    this.loading = false;
                }
            });
        },

        sweealert2(icon, text) {
            this.$swal({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                html: '&nbsp;&nbsp;'+text,
                icon: icon,
                padding: 20,
                background: '#dbf6e9'
            })
        },

        schoolingName() {
            let school = this.schooling_options.find(schooling => schooling.value === this.schooling)
            return school?.text
        },
    },

    computed: {

        ...mapGetters('worker', [
            'OpenForm', 'IsUpdate', 'IsStage'
        ]),

        ...mapGetters('auth', [
            'has_permission'
        ]),

        extension_curses: {
            get() {
                return this.$store.state.worker.worker.detail.extension_curses;
            },
            set(value) {
                this.$store.commit('worker/SET_EXTENSION_CURSES', value)
            }
        },

        graduated_at: {
            get() {
                return this.$store.state.worker.worker.detail.graduated_at;
            },
            set(value) {
                this.$store.commit('worker/SET_GRADUATED_AT', value)
            }
        },

        schooling: {
            get() {
                return this.$store.state.worker.worker.detail.schooling;
            },
            set(value) {
                this.$store.commit('worker/SET_SCHOOLING', value)
            }
        },

        read: {
            get() {
                return this.$store.state.worker.worker.detail.read;
            },
            set(value) {
                this.$store.commit('worker/SET_READ', value)
            }
        },

        write: {
            get() {
                return this.$store.state.worker.worker.detail.write;
            },
            set(value) {
                this.$store.commit('worker/SET_WRITE', value)
            }
        },

        errors: {
            get() {
                return this.$store.state.worker.errors;
            },
        },
    }
}
</script>
